<template>
  <v-row class="mb-16">
    <v-col
      cols="2"
      class="pr-1"
    >
      <label>
        Кол-во мест
        <input-numeric
          ref="cargo_place_quantity"
          v-model="item.cargo_place_quantity"
          :fraction="0"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        />
      </label>
    </v-col>
    <v-col
      cols="2"
      class="px-2"
    >
      <label>
        Вес брутто (кг)
        <input-numeric
          ref="gross_weight"
          v-model="item.gross_weight"
          :fraction="3"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        />
      </label>
    </v-col>
    <v-col
      cols="2"
      class="px-2"
    >
      <label>
        Код
        <v-autocomplete
          ref="package_info_code"
          v-model="item.package_info_code"
          auto-select-first
          item-text="text"
          item-value="value"
          :items="packageInfoCodes"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        />
      </label>
    </v-col>
    <v-col
      cols="2"
      class="px-2"
    >
      <label>
        Вид упаковки
        <v-autocomplete
          ref="package_type_code"
          v-model="item.package_type_code"
          auto-select-first
          outlined
          dense
          hide-details="auto"
          background-color="white"
          :items="packageTypes"
          item-text="text"
          item-value="code"
          :filter="filterBySearchField"
        />
      </label>
    </v-col>
    <v-col
      cols="2"
      class="px-2"
    >
      <label>
        Код
        <v-autocomplete
          ref="package_description"
          v-model="item.package_description"
          auto-select-first
          item-text="text"
          item-value="value"
          :items="packageDescriptions"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        />
      </label>
    </v-col>
    <v-col
      cols="2"
      class="px-2"
    >
      <label>
        Кол-во упак.
        <input-numeric
          ref="package_quantity"
          v-model="item.package_quantity"
          :fraction="0"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        />
      </label>
    </v-col>
    <v-col
      v-if="!item.containers_json.length"
      cols="12"
    >
      <v-btn
        dense
        class="elevation-0"
        @click="addContainer"
      >
        <v-icon>mdi-plus</v-icon> Добавить контейнер
      </v-btn>
    </v-col>
    <v-row
      v-show="item.containers_json.length"
      class="mx-0"
    >
      <container
        v-for="(item, containerIndex) in item.containers_json"
        :key="`container-${containerIndex}`"
        :index="containerIndex"
        :item="item"
        @add="addContainer"
        @delete="deleteContainer"
        @change="onContainerChange"
        @copy="onContainerCopy"
      />
    </v-row>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import { normalizeFloat } from "@/helpers/math";
import Container from "./container.vue";
import cloneDeep from "lodash.clonedeep";
import { updateGoodParameters } from "@/events/statistics";
import HighlightFieldWareSector from "@/mixins/control/highlight-field-ware-sector";
import copyFieldMixin from "@/mixins/copy-field.mixin";
import InputNumeric from "@/components/ui/input-numeric.vue";


export default {
  components: {InputNumeric, Container },
  mixins: [filterBySearchFieldMixin, HighlightFieldWareSector, copyFieldMixin],
  props: {
    index: {
      required: true,
      type: Number,
    },
    info: {
      required: true,
      type: Object,
    },
    visited: {
      required: true,
      type: Boolean,
    },
    hasChanges: {
      required: true,
      type: Boolean,
    },
  },
  data: () => ({
    packageInfoCodes:[
      {
        text:'0 - без упаковки',
        value:0
      },
      {
        text:'1 - с упаковкой',
        value:1
      },
      {
        text:'2 - без упаковки, в оборудованных емкостях транспортного средства',
        value:2
      },
    ],
    packageDescriptions:[
      {
        text:'0 - сведения об упаковке',
        value:"0"
      },
      {
        text:'1 - сведения о таре',
        value:"1"
      },
      {
        text:'2 - сведения о грузе',
        value:"2"
      },
      {
        text:'3 - сведения о поддонах',
        value:"3"
      },
      {
        text:'4 - сведения об индивидуальной упаковке',
        value:"4"
      },
    ],
    item: {
      id: "",
      cargo_place_quantity: "",
      package_type_code: "",
      package_info_code: "",
      package_quantity:"",
      package_description:"",
      gross_weight: "",
      containers_json: [],
    },

  }),
  computed: {
    ...mapGetters({
      selected: "epi/getSelected",
      shipmentIndex:'epi/getShipmentIndex',
      packageTypes:'catalogs/getNsiPackageTypes'
    }),
    selectedWares(){
      return this.selected.ware_shipments[this.shipmentIndex]?.wares ?? []
    }
  },
  watch: {
    selectedWares: {
      handler() {
        this.setFields();
      },
      immediate: true,
      deep: true,
    },
    item: {
      handler() {
        // Пропс visited обвновляется позже чем вызывается обработчик,
        // поэтому нужно свойство внутри компонента
        if (this.visited) {
          this.updateEditableGoodsItem();
        }
      },
      deep: true,
    },
    "item.cargo_place_quantity": {
      handler(nv) {
        const result = nv === null || nv === "" ? "" : Number.parseInt(nv);
        this.$nextTick(() => (this.item.cargo_place_quantity = result));
      },
    },
  },
  created() {
    updateGoodParameters.subscribe(this.onParamsChange);
  },
  beforeDestroy() {
    updateGoodParameters.unsubscribe();
  },
  methods: {
    onContainerCopy(type){
      if(type === 'all'){
        this.copyToAll('containers_json', this.item.containers_json)
      }
      if(type === 'selected'){
        this.copy('containers_json', this.item.containers_json, 'apply')
      }
    },
    replaceFloatNumbers({ value, prop, afterPoint = null }) {
      this.item[prop] = normalizeFloat({ value, afterPoint });
    },
    onParamsChange({ id, payload }) {
      if (id === this.item.id) {
        const { gross_weight } = payload;
        this.item.gross_weight = gross_weight;
        this.readyToUpdate();
      }
    },
    onContainerChange({ index, value }) {
      this.item.containers_json.splice(index, 1, value);
      this.readyToUpdate();
    },
    addContainer() {
      const value = { number: "" };
      this.item.containers_json.push(value);
      this.readyToUpdate();
    },
    deleteContainer(index) {
      this.item.containers_json.splice(index, 1);
      this.readyToUpdate();
    },
    updateEditableGoodsItem() {
      this.$emit("update:info", this.item);
    },
    setFields() {
      const ware = cloneDeep(this.selectedWares[this.index]);
      Object.keys(this.item).forEach((key) => {
        if (key in ware) {
          this.item[key] = ware[key];
        }
      });
    },
    readyToUpdate() {
      this.$emit("update:visited", true);
      this.$emit("update:has-changes", true);
    },
  },
};
</script>

<style scoped>
th {
  font-size: 10px !important;
}
td {
  font-size: 12px !important;
}
</style>
