import { getCountryNameByCode } from "@/helpers/catalogs";
import {openGoodsModal} from '@/events/common'
export default {
  data:()=>({
    applyAllDialogBoolean: false,
    applyAllDialog: null
  }),
  methods:{
    openGoodsModal(field, value, action){
      if(Array.isArray(field)){
        if(field.length > 2){
          const [a, b, c] = field
          value = {
            [a]: this.item[a],
            [b]: this.item[a] ? getCountryNameByCode(this.countries, this.item[a]): null,
            [c]: this.item[c],
          }
        }else{
          const [a, b] = field
          value = {
            [a]: this.item[a],
            [b]: this.item[a] ? getCountryNameByCode(this.countries, this.item[a]): null
          }
        }
        openGoodsModal.trigger({ field, value, action })
      }else{
        openGoodsModal.trigger({ field, value, action })
      }
    },
    applyToAll(){
      if(!('field' in this.applyAllDialog)){
        return this.$snackbar({text:'Ошибка', color:'red', top:false})
      }
      if(Array.isArray(this.applyAllDialog.field)){
        const [a,b] = this.applyAllDialog.field
        this.applyAllDialog.value = {
          [a]: this.item[a],
          [b]: this.item[a] ? getCountryNameByCode(this.countries, this.item[a]) : null
        }
        this.$store.dispatch('statistics/applyToAll', { id: this.selected.id, values: this.applyAllDialog.value })
          .then(() => {
            this.$snackbar({text:"Действие применено", top:false, right: false})
          })
          .catch(() => {
            this.$snackbar({text:'Ошибка', color:'red', top:false, right: false})
          })
      }else{
        this.$store.dispatch('statistics/applyToAll', { id: this.selected.id, values: {[this.applyAllDialog.field]: this.applyAllDialog.value} })
          .then(() => {
            this.$snackbar({text:"Действие применено", top:false, right: false})
          })
          .catch(() => {
            this.$snackbar({text:'Ошибка', color:'red', top:false, right: false})
          })
      }
      this.applyAllDialog = null
      this.applyAllDialogBoolean = false
    }
  },
    
}