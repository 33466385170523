
import { mapGetters } from "vuex"
export default {
  data:() => ({
    _resizeObserver:null
  }),
  computed:{
    ...mapGetters({
      pinned:'ui/getPinned'
    })
  },
  beforeDestroy(){
    this._resizeObserver.disconnect()
  },
  methods:{
    _connectResizeObserver(){
      const element = document.getElementById('pinned-block')
      const section = document.querySelector('section.sd-main')
      this._resizeObserver = new ResizeObserver(([entry])=> {
        if(this.pinned.length){
          const [block] = entry.borderBoxSize
          const height = block.blockSize - 80
          return section.style.marginTop = `${height}px`
        }
        return section.style.marginTop = '0px'
      })
      this._resizeObserver.observe(element)
    }
  },
  mounted(){
    this._connectResizeObserver()
  }
}