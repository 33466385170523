<template>
  <v-container fluid>
    <v-row>
      <v-col
        id="epi-guarantee"
        class="statistics-box statistics-guarantee-list mx-3 my-2"
        :class="{'blocked-box' : disabledView}"
      >
        <v-row>
          <v-col
            cols="12"
            class="pb-0"
          >
            <div class="font-weight-bold d-flex align-center mt-2">
              52 Гарантия
              <v-menu offset-x>
                <template #activator="{ on, attrs }">
                  <v-icon
                    tabindex="-1"
                    class="mx-1"
                    small
                    dense
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-tune
                  </v-icon>
                </template>
                <v-list>
                  <v-list-item @click="hideTooltips = !hideTooltips">
                    {{ hideTooltips ? "Включить" : "Отключить" }} подсказки
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col cols="6">
                <v-row>
                  <v-col cols="4">
                    <label>Сумма платежей</label>
                    <input-numeric
                      :value="common_payment_sum_byn"
                      class="mr-1"
                      outlined
                      dense
                      readonly
                      hide-details="auto"
                      background-color="grey lighten-2"
                      suffix="BYN"
                    />
                  </v-col>
                  <v-col cols="4">
                    <label>Сумма платежей</label>
                    <input-numeric
                      :value="common_payment_sum_eur"
                      class="mr-1"
                      outlined
                      dense
                      readonly
                      hide-details="auto"
                      background-color="grey lighten-2"
                      suffix="EUR"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <div>
              <v-simple-table
                v-if="warrantyItems.length"
                class="bg-gray"
                fixed-header
                dense
              >
                <template #default>
                  <thead>
                    <tr>
                      <th>Код меры</th>
                      <th>Номер документа</th>
                      <th>Дата</th>
                      <th>Сумма гарантии</th>
                      <th>УНП</th>
                      <th>Код банка</th>
                      <th>Лицо, осуществляющее сопровождение</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    <warranty-item
                      v-for="(item, index) in warrantyItems"
                      :key="index"
                      :banks="banks"
                      :item="item"
                      :index="index"
                      :items-amount="warrantyItems.length"
                      :guarantee-types="guaranteeTypes"
                      :hide-tooltips="hideTooltips"
                      :control="warrantyControlPath"
                    />
                  </tbody>
                </template>
              </v-simple-table>
              <v-btn
                v-else
                text
                @click="addNewWarranty"
              >
                <v-icon small>
                  mdi-plus
                </v-icon>
                Добавить гарантию
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import WarrantyItem from "@/components/epi/form/warranty/warranty-item.vue";
import { getScrollOffset } from "@/helpers/control";
import { guarantees as onGuarantee } from "@/events/statistics/control";
import InputNumeric from "@/components/ui/input-numeric.vue";

export default {
  components: {InputNumeric, WarrantyItem },
  data: () => ({
    collapsed: false,
    banks: [],
    warrantyItems: [],
    hideTooltips: true,
    common_payment_sum_byn: null,
    common_payment_sum_eur: null,
    warrantyControlPath: {
      path: null
    },
  }),
  computed: {
    ...mapGetters({
      selected: "epi/getSelected",
      guaranteeTypes: "catalogs/gethNsiGuaranteeTypes",
      disabledView:"epi/getVisibility"
    }),
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
    },
  },
  created() {
    this.loadBankBIC();
    onGuarantee.subscribe(this.onControl);
  },
  beforeDestroy() {
    onGuarantee.unsubscribe();
  },
  methods: {
    onControl(data) {
      const docs = document.querySelector(".statistics-guarantee-list");
      const offset = getScrollOffset();
      this.$scrollTo(docs, 200, {
        force: true,
        offset,
        onDone: () => this.highlight(data),
      });
    },
    highlight(data) {
      this.warrantyControlPath = data
    },
    loadBankBIC() {
      const uninterceptedAxiosInstance = axios.create();
      return uninterceptedAxiosInstance
        .get("https://www.nbrb.by/api/bic")
        .then((res) => {
          this.banks = res.data.map((item) => {
            return {
              ...item,
              search: `${item.CDBank} - ${item.NmBankShort}`,
            };
          });
        });
    },
    setFields() {
      this.warrantyItems = this.selected.guarantees;
      this.common_payment_sum_byn = this.selected.common_payment_sum_byn;
      this.common_payment_sum_eur = this.selected.common_payment_sum_eur;
    },
    addNewWarranty() {
      this.$store.dispatch("epi/createWarranty", this.selected.id);
    },
  },
};
</script>

<style scoped>
.bg-gray {
  background-color: #ededed !important;
}
thead tr th {
  padding-left: 0 !important;
  background-color: #ededed !important;
  box-shadow: none !important;
}
</style>
