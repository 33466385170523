import moment from "moment";

export function removeCodeKeysFromVehicle(vehicle){
  const keys = Object.keys(vehicle).filter(key => !key.endsWith('_code'))
  return Object.entries(vehicle).reduce((previous, current) => {
    const [key, value] = current
    return keys.includes(key) ? {...previous, [key]:value} : previous
  }, {})
}
export function getVehicleText(vehicle){
  const {
    vehicle_type_name, mark_name, model_name, vin , release_date, volume
  } = vehicle
  const mainText = [vehicle_type_name, mark_name, model_name].filter(part => !!part).join(' ')
  const vinText = vin ? `VIN: ${vin}` : null
  const volumeText = volume ? `ОБЪЕМ ДВИГАТЕЛЯ ${volume} СМ3`: null
  const releaseDateText = getReleaseDateText(release_date)
  return [mainText,vinText,releaseDateText, volumeText].filter(part => !!part).join('; ')
}

function getReleaseDateText(releaseDate){
  const year = moment(releaseDate,'YYYY-MM-DD').isValid() ? moment(releaseDate, 'YYYY-MM-DD').format('YYYY'): null
  return year ?`${year} года выпуска` : null
}