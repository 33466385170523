export function getExciseNeed(value) {
  return "tarifId" in value ? value.tarifId !== -1 : value.id !== -1;
}

export function getAntiDumpingNeed(tarifId){
  return tarifId !== -1
}
export function getAvailableExcise({availableExcises, exciseId, transitExciseId}) {
  if (availableExcises.type === 1) {
    return (
      availableExcises?.data?.find(
        (i) => i.id === transitExciseId
      ) ?? null
    );
  } else if (availableExcises.type === 2) {
    return (
      availableExcises?.data?.find(
        (i) => i.tarifId === exciseId
      ) ?? null
    );
  } else {
    return null;
  }
}