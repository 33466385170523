<template>
  <v-overlay
    :value="show"
  >
    <v-card
      v-click-outside="close"
      width="500px"
      light
      color="#333333"
    >
      <v-card-title class="white--text d-flex justify-space-between">
        <span>Подтвердите действие</span>
        <v-btn
          color="error"
          small
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="white text-lg-body-1 py-5">
        Вы действительно хотите удалить партию ?
      </v-card-text>

      <div class="d-flex justify-end">
        <v-btn
          dark
          color="#5CB7B1"
          class="mx-5 my-2"
          @click="deleteShipment"
        >
          Подтвердить
        </v-btn>
      </div>
    </v-card>
  </v-overlay>
</template>
<script>


import modalWindowMixin from "@/mixins/modal-window.mixin";

export default {
  mixins:[modalWindowMixin],
  props:{
    show:{
      required:true,
      type:Boolean
    },
    module:{
      type:String,
      required:true
    }
  },
  methods:{
    deleteShipment(){
      this.$store.dispatch(`${this.module}/deleteShipment`)
        .then(() => {
          this.$snackbar({text:'Партия удалена',color:'blue', top:false})
          this.close()
        })
        .catch(() => {
          this.$snackbar({text:'Ошибка',color:'red', top:false})
        })
    }
  }
}
</script>
