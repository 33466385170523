export function fillStateByCarrier(carrier){
  if (carrier) {
    this.warranty_item.number = carrier.certificate_number
    this.warranty_item.date = carrier.certificate_date
  }
}
export function getActiveDivisionId(){
  return this.user.user.activeDivisionId
}
export function fillStateFromDeclarant(){
  const declarantUnp = this.getDeclarantUnp()
  const operator = this.getOperatorByUnp(declarantUnp)
  this.warranty_item = {
    ...this.warranty_item,
    number: operator?.licenseNumber ?? null,
    date: operator?.dOn ?? null,
    sum: null,
    currency_letter: null,
    bank_id: null,
    subject_name: null
  }
}

export function getDeclarantUnp(){
  return this.selected.principal.principal_inn;
}
export function getOperatorByUnp(unp){
  return this.aeos.find(i => i.unp === unp)
}

export function getCarrierUnp(){
  return this.selected.carrier.carrier_inn
}
export function findCarrierByUnp(){
  const divisionId = this.getActiveDivisionId()
  const value = this.getCarrierUnp()
  return this.$store.dispatch('catalogs/searchCarrier', {
    value,
    divisionId
  }).then(res => {
    const carriers = res.data ?? []
    const carriersWithCertificate = carriers.filter((carrier) => carrier.certificate_number !== null)
    return carriersWithCertificate.length ? carriersWithCertificate[0] : null
  })
}

export function fillStateFromEnsuring(certificateId){
  this.$store.dispatch('ensuring/getDeclarationById', certificateId)
    .then((res) => {
      const certificate = res.data
      const {currency_letter, sum} = certificate.about_guarantee
      const {reg_num_a: number, reg_date_a: date } = certificate.registration_details
      this.warranty_item = {
        ...this.warranty_item,
        number,
        date,
        sum,
        currency_letter
      }
      this.readyToUpdate()
    })
    .catch(err => this.$error(err.response.data || "Ошибка"))
}