import {convertEmptyStringsToNull} from "@/helpers/objects";
export function copyToBlock(namespace, target){

  // namespace -> carrier target-> principal
  const current = this.selected[target]
  const current_json = current[`${target}_json`]
  const combined = {
    ...current
  }
  const combined_json = {
    ...current_json,
  }

  Object.keys(current).filter(k => k !== `${target}_json`).forEach(key => {
    const original = key.replace(`${target}_`, `${namespace}_`)
    if(original in this[namespace]){
      combined[key] = this[namespace][original]
    }
  })

  Object.keys(current_json).forEach(key => {
    if(key in this[`${namespace}_json`]){
      combined_json[key] = this[`${namespace}_json`][key]
    }
  })
  const payload = convertEmptyStringsToNull({
    ...combined,
    [`${target}_json`]: {
      ...combined_json,
      contacts: 'getContactsWithName' in this ? this.getContactsWithName() : []
    }
  })
  this.$store.dispatch("epi/uploadBlockData", {
    name: target,
    value: payload,
  }).then(() => {
    this.$snackbar({
      text: "Обновлено",
      color: "green",
      top: false,
    });
  })
    .catch(() => {
      this.$snackbar({
        text: "Ошибка сохранения блока",
        color: "red",
        top: false,
      });
    });
}