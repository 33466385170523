<template>
  <div
    :id="block_id"
    v-click-outside="triggerOnFocus"
    class="statistics-box pa-4"
    :class="{'blocked-box' : disabledView}"
  >
    <v-row class="pb-4">
      <v-col>
        <div class="font-weight-bold d-flex align-center">
          2 Отправитель
          <v-menu offset-x>
            <template #activator="{ on, attrs }">
              <v-icon
                class="mx-1 mr-4"
                tabindex="-1"
                small
                dense
                v-bind="attrs"
                v-on="on"
                @click="onMenuOpen"
              >
                mdi-tune
              </v-icon>
            </template>
            <v-list>
              <v-list-item> Поиск в справочнике</v-list-item>
              <v-list-item @click="show = true">
                Сохранить организацию в справочник
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-col>
      <v-col
        cols="7"
        class="pb-0"
      >
        <div class="d-flex">
          <v-autocomplete
            v-model="search"
            class="ml-16"
            :loading="loading"
            :readonly="loading"
            :items="exporters"
            item-text="own_name"
            :filter="filterBySearchField"
            placeholder="Поиск в справочнике"
            auto-select-first
            return-object
            append-icon="mdi-magnify"
            background-color="white"
            dense
            outlined
            hide-details="true"
            tabindex="-1"
            @update:search-input="fetchExporters"
          />
          <div class="d-flex justify-end">
            <v-btn
              min-width="32px"
              width="36px"
              class="ml-2"
              text
              @click="collapsed = !collapsed"
            >
              <v-icon>
                {{ collapsed ? "mdi-chevron-down" : "mdi-chevron-up" }}
              </v-icon>
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row v-show="!collapsed">
      <v-col
        cols="2"
        class="pb-0 pr-1"
      >
        <label>
          Страна
          <v-autocomplete
            ref="country_letter"
            v-model="sender_json.country_letter"
            auto-select-first
            outlined
            dense
            hide-details="auto"
            background-color="white"
            :items="countries"
            item-text="letterCode"
            item-value="letterCode"
            :filter="filterBySearchField"
          />
        </label>
      </v-col>
      <v-col
        cols="3"
        class="pb-0"
      >
        <label>
          УНП (ИНН И т.п.)
          <v-text-field
            ref="sender_inn"
            v-model="sender.sender_inn"
            class="mr-1"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </label>
      </v-col>
      <v-col
        cols="7"
        class="pb-0"
      >
        <label>
          Наименование организации
          <v-text-field
            ref="sender_name"
            v-model="sender.sender_name"
            class="mr-1"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </label>
      </v-col>
      <v-col
        cols="3"
        class="pb-0"
      >
        <label>
          Индекс
          <v-text-field
            ref="postal_index"
            v-model="sender_json.postal_index"
            class="mr-1"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </label>
      </v-col>
      <v-col
        cols="5"
        class="pb-0"
      >
        <label>
          Область/Район
          <v-text-field
            ref="region"
            v-model="sender_json.region"
            class="mr-1"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </label>
      </v-col>
      <v-col
        cols="4"
        class="pb-0"
      >
        <label>
          Город/Деревня
          <v-text-field
            ref="city"
            v-model="sender_json.city"
            class="mr-1"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </label>
      </v-col>
      <v-col
        cols="7"
      >
        <label>
          Улица
          <v-text-field
            ref="street_house"
            v-model="sender_json.street_house"
            class="mr-1"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </label>
      </v-col>
      <v-col
        cols="2"
      >
        <label>
          Дом
          <v-text-field
            ref="house"
            v-model="sender_json.house"
            class="mr-1"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </label>
      </v-col>
      <v-col
        cols="3"
      >
        <label>
          Номер помещ.
          <v-text-field
            ref="room"
            v-model="sender_json.room"
            class="mr-1"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </label>
      </v-col>
    </v-row>
    <exporter-collapsed
      v-show="collapsed"
      :value="sender"
      :json="sender_json"
    />
    <archive
      :show.sync="show"
      entity="sender"
      @save="saveToArchive"
    />
  </div>
</template>

<script>
import Archive from "./archive.vue";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import { mapGetters } from "vuex";
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import { getCountryNameByCode } from "@/helpers/catalogs";
import { convertEmptyStringsToNull } from "@/helpers/objects";
import { sender as onSender } from "@/events/statistics/control";
import { highlightField } from "@/helpers/control";
import ExporterCollapsed from "@/components/epi/form/contragents/exporter-collapsed.vue";
export default {
  components: { Archive, ExporterCollapsed },
  mixins: [filterBySearchFieldMixin, blockAutoUpdate],
  data: () => ({
    show: false,
    block_id: "epi-sender",
    collapsed:false,
    sender: {
      ware_shipment_id: null,
      sender_inn: "",
      sender_name: "",
    },
    sender_json: {
      country_letter: "",
      country_name: "",
      postal_index: "",
      region: "",
      city: "",
      street_house: "",
      house: "",
      room: "",
    },
    // поиск в справочнике
    exporters: [],
    loading: false,
    search: null,
  }),
  computed: {
    divisionId() {
      return this.user?.user?.activeDivisionId;
    },
    ...mapGetters({
      user: "auth/getUser",
      selected: "epi/getSelected",
      countries: "catalogs/getNsiCountries",
      contact_types: "catalogs/getContactTypes",
      shipmentIndex:'epi/getShipmentIndex',
      disabledView:"epi/getVisibility"
    }),
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
      immediate:true,
    },
    shipmentIndex(){
      this.setFields()
    },
    search(nv) {
      if (nv && Object.keys(nv).length) {
        this.updateFieldsOnSearch(nv);
        this.search = null;
        this.importers = [];
        this.forceUpload();
      }
    },
  },
  created() {
    onSender.subscribe(this.onControl);
  },
  beforeDestroy() {
    onSender.unsubscribe();
  },
  methods: {
    highlightField,
    onControl(data){
      this.$nextTick(() => {
        this.highlightField(data)
      })
    },
    forceUpload() {
      return this.uploadData()
        .then((res) => {
          this.$snackbar({
            text: "Обновлено [Отправитель]",
            color: "green",
            top: false,
            right: false,
          });
          return res;
        })
        .catch((err) => {
          this.$snackbar({
            text: "Ошибка",
            color: "red",
            top: false,
            right: false,
          });
          return Promise.reject(err);
        });
    },
    saveToArchive(own_name) {
      const payload = {
        id: null,
        division_id: this.divisionId,
        own_name: own_name,
        name: this.sender.sender_name,
        person_name: null,
        unp: this.sender.sender_inn,
        country_letter: this.sender_json.country_letter,
        country_name: this.sender_json.country_name,
        region: this.sender_json.region,
        city: this.sender_json.city,
        street: this.sender_json.street_house,
        house: this.sender_json.house,
        room: this.sender_json.room,
        postal_index: this.sender_json.postal_index,
        contacts: [],
        subject_doc_num: null,
        subject_doc_date: null,
      };


      // проверка на резидента
      let url = "";
      if (payload.country_letter === "BY") {
        url = "division/saveResidentRaw";
      } else {
        url = "division/saveNoResidentRaw";
        payload.inn = payload.unp;
        delete payload.unp;
      }

      this.$store
        .dispatch(url, payload)
        .then(() => {
          this.$snackbar({
            text: "Справочник обновлен",
            color: "blue",
            top: false,
            right: false,
          });
        })
        .catch((err) => {
          if (err.response.status === 400) {
            this.$snackbar({
              text: err.response.data || "Ошибка",
              color: "red",
              top: false,
              right: false,
            });
          } else {
            this.$snackbar({
              text: "Ошибка",
              color: "red",
              top: false,
              right: false,
            });
          }
        });
    },
    updateFieldsOnSearch(item) {
      this.sender.sender_name = item.name;
      this.sender.sender_inn = item.unp ? item.unp : item.inn;

      Object.keys(this.sender_json).forEach((key) => {
        if (key in item) {
          this.sender_json[key] = item[key];
        }
      });

      this.sender_json.street_house = item.street;

    },
    setFields() {
      const {sender} = this.selected.ware_shipments[this.shipmentIndex];
      const {sender_json} = sender;

      Object.keys(this.sender).forEach((key) => {
        if (key in sender) {
          this.sender[key] = sender[key];
        }
      });

      Object.keys(this.sender_json).forEach((key) => {
        if (key in sender_json) {
          this.sender_json[key] = sender_json[key];
        }
      });

    },
    uploadData() {
      const sender = this.getPreparedObject();
      return this.$store.dispatch("epi/uploadShipmentBlockData", {
        name: "sender",
        value: sender,
      });
    },
    getPreparedObject() {
      const payload = {
        ware_shipment_id: this.sender.ware_shipment_id,
        sender_inn: this.sender.sender_inn,
        sender_name:  this.sender.sender_name,
        sender_json: {
          ...this.sender_json,
          country_name:
            getCountryNameByCode(
              this.countries,
              this.sender_json.country_letter
            ) || null,
        },
      };
      return convertEmptyStringsToNull(payload);
    },
    onMenuOpen() {
      if (this.hasChanges && this.fields_been_visited) {
        this.forceUpload().then(() => {
          this.fields_been_visited = false;
          this.hasChanges = false;
        });
      }
    },
    fetchExporters(value) {
      if (value && value.length > 3) {
        this.loading = true;
        Promise.all([
          this.$store.dispatch("division/searchResident", value),
          this.$store.dispatch("division/searchNoResident", value),
        ])
          .then(([residents, noResidents]) => {
            this.loading = false;
            const results = [...residents.data, ...noResidents.data];
            const items = results.map((i) => {
              return {
                ...i,
                search: `${i.name}-${i.own_name}-${i.unp || i.inn}-${i.city}-${
                  i.street
                }`,
              };
            });
            this.exporters = [...items];
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
