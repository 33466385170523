export default {
  methods:{
    addContactItem() {
      this.contacts.push({
        code: "",
        name: "",
        number: "",
      });
      this.readyToUpdate();
    },
    deleteContactItem(index) {
      this.contacts.splice(index, 1);
      this.readyToUpdate();
    },
    readyToUpdate() {
      this.hasChanges = true;
      this.fields_been_visited = true;
    },
    getContactsWithName() {
      return this.contacts.map((item) => {
        const { name } =
        this.contact_types.find((i) => i.code === item.code) ?? {};
        return {
          ...item,
          name,
        };
      });
    },
    contactsUpdater(payload) {
      this.contacts[payload.index] = payload.cont_item;
      this.readyToUpdate();
    },
  }
}