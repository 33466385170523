import { render, staticRenderFns } from "./shipments.vue?vue&type=template&id=0655fffa&scoped=true"
import script from "./shipments.vue?vue&type=script&lang=js"
export * from "./shipments.vue?vue&type=script&lang=js"
import style0 from "./shipments.vue?vue&type=style&index=0&id=0655fffa&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0655fffa",
  null
  
)

export default component.exports