<template>
  <div>
    <v-sheet rounded>
      <v-simple-table dense>
        <template #default>
          <thead>
            <tr>
              <th class="text-left">
                Вид
              </th>
              <th class="text-left">
                Основа начисления
              </th>
              <th class="text-left">
                Ставка
              </th>
              <th class="text-left">
                Сумма
              </th>
              <th class="text-left">
                СП
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(i, idx) in payments"
              :key="idx"
            >
              <td>{{ i.code }}</td>
              <td>{{ i.basisPretty }}</td>
              <td>
                {{ i.rate }} {{ i.rate_type }}<span v-if="i.measure_unit_letter"> за {{ i.measure_unit_letter }}</span>
              </td>
              <td>{{ i.sumPretty }} {{ i.currency_letter }}</td>
              <td>{{ i.payment_feature }}</td>
            </tr>
            <tr>
              <td
                class="font-weight-bold"
                colspan="3"
              >
                Всего:
              </td>
              <td class="font-weight-bold">
                {{ totalSum }} BYN
              </td>
              <td />
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-divider />
    </v-sheet>
    <div class="mt-2">
      <v-btn
        color="#5CB7B1"
        small
        class="white--text"
        @click="$emit('clear')"
      >
        Изменить
      </v-btn>
    </div>
  </div>
</template>
<script>
import {prettifyNumber} from "@/helpers/math";

export default {
  props: {
    payments: {
      type: Array,
      required: true
    }
  },
  computed:{
    totalSum(){
      const sum = this.payments.reduce((previousValue, currentValue) => {
        return previousValue + +currentValue.sum
      }, 0).toFixed(2)
      return prettifyNumber(sum)
    }
  },
}
</script>