<template>
  <tr
    :id="block_id"
    v-click-outside="triggerOnFocus"
  >
    <td width="40px">
      <v-btn
        min-width="36px"
        max-width="36px"
        height="36px"
        plain
        dense
        @click="searchImage"
      >
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </td>
    <td width="300px">
      <v-text-field
        v-model.trim="current.description"
        outlined
        dense
        hide-details="auto"
        background-color="white"
      />
    </td>
    <td>
      <v-text-field
        v-model.trim="current.article"
        outlined
        dense
        hide-details="auto"
        background-color="white"
      />
    </td>
    <td>
      <v-text-field
        v-model.trim="current.trade_mark"
        outlined
        dense
        hide-details="auto"
        background-color="white"
      />
    </td>
    <td width="70px">
      <v-text-field
        outlined
        dense
        hide-details="auto"
        background-color="white"
        :value="current.ware_quantity"
        @input="(v) => (current.ware_quantity = v.replace(',', '.').trim())"
      />
    </td>
    <td width="70px">
      <v-text-field
        v-model.trim="current.measure_unit_letter"
        outlined
        dense
        hide-details="auto"
        background-color="white"
      />
    </td>
    <td>
      <v-text-field
        outlined
        dense
        hide-details="auto"
        background-color="white"
        :value="current.net_weight"
        @input="(v) => (current.net_weight = v.replace(',', '.').trim())"
        @change="(value) => replaceFloatNumbers({value, prop:'net_weight'})"
      />
    </td>
    <td>
      <v-text-field
        outlined
        dense
        hide-details="auto"
        background-color="white"
        :value="current.net_weight_no_pack"
        @input="(v) => (current.net_weight_no_pack = v.replace(',', '.').trim())"
        @change="(value) => replaceFloatNumbers({value, prop:'net_weight_no_pack'})"
      />
    </td>
    <td>
      <v-text-field
        outlined
        dense
        hide-details="auto"
        background-color="white"
        :value="current.gross_weight"
        @input="(v) => (current.gross_weight = v.replace(',', '.').trim())"
        @change="(value) => replaceFloatNumbers({value, prop:'gross_weight'})"
      />
    </td>
    <td>
      <v-text-field
        outlined
        dense
        hide-details="auto"
        background-color="white"
        :value="current.invoice_cost"
        @input="(v) => (current.invoice_cost = v.replace(',', '.').trim())"
        @change="(value) => replaceFloatCost({value, prop: 'invoice_cost'})"
      />
    </td>
    <td width="100px">
      <v-text-field
        :disabled="showQuantity === false"
        :background-color="!showQuantity ? 'grey lighten-2' : 'white' "
        outlined
        dense
        hide-details="auto"
        :value="current.add_measure_unit_quantity"
        @input="
          (v) =>
            (current.add_measure_unit_quantity = v.replace(',', '.').trim())
        "
        @change="(value) => replaceFloatNumbers({value, prop:'add_measure_unit_quantity'})"
      />
    </td>
    <td class="d-flex align-center">
      <!-- <v-btn
        min-width="36px"
        max-width="36px"
        height="36px"
        color="#5CB7B1"
        @click="remove"
        class="ware-delete-article"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn> -->
      <v-btn
        max-width="36px"
        min-width="36px"
        color="transparent"
        class="elevation-0 ware-delete-article"
        @click="remove"
      >
        <v-icon small>
          mdi-delete
        </v-icon>
      </v-btn>
      <v-btn
        min-width="36px"
        max-width="36px"
        height="36px"
        color="transparent"
        class="elevation-0 ware-copy-article"
        @click="copyGoodItem"
      >
        <v-icon small>
          mdi-content-copy
        </v-icon>
      </v-btn>
      <v-btn
        v-if="index === goodsLength - 1"
        min-width="36px"
        max-width="36px"
        height="36px"
        color="transparent"
        class="elevation-0 ware-add-article"
        @click="addGoodItem"
      >
        <v-icon small>
          mdi-plus
        </v-icon>
      </v-btn>
    </td>
  </tr>
</template>
<script>
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import { convertEmptyStringsToNull } from "@/helpers/objects";
import { mapGetters } from "vuex";
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import {
  forceUpdateWareDetail,
  // wareDatailForceUpdated,
  wareDetailModified,
  addPromise
} from "@/events/statistics";
import {googleImage } from "@/helpers/text"

export default {
  mixins: [blockAutoUpdate, filterBySearchFieldMixin],
  block_id: null,
  props: {
    item: {
      required: true,
      type: Object,
    },
    index: {
      // В текщем списке
      required: true,
      type: Number,
    },
    wareIndex: {
      // Родительский
      required: true,
      type: Number,
    },
    showQuantity:{
      required: true,
      type: Boolean,
    },
    goodsLength: {
      required: true,
      type: Number
    }
  },
  data: () => ({
    current: {
      id: "",
      ware_id: "",
      sort_index: "",
      description: "",
      article: "",
      trade_mark: "",
      ware_quantity: "",
      measure_unit_letter: "",
      net_weight: "",
      net_weight_no_pack: "",
      gross_weight: "",
      invoice_cost: "",
      add_measure_unit_quantity: "",
      statistical_cost:"",
      excise_quantity:""
    },
  }),
  computed: {
    ...mapGetters({
      measure_units: "catalogs/getNsiMeasureUnits",
    }),
  },
  watch: {
    item: {
      handler() {
        this.block_id = `epi-${this.wareIndex}-vendor-code-${this.index}`;
        this.setFields();
      },
      immediate: true,
    },
    current: {
      handler() {
        // После пользовательского изменения уведовляем родительский компонент
        // emitted ставится в значение false после каждого запроса, чтобы можно было продолжать редактирование

        if (this.fields_been_visited && !this.emitted) {
          wareDetailModified.trigger({
            index: this.index,
            action: "update",
          });
        }
        this.$emit("update", this.current);
      },
      deep: true,
    },
  },
  created() {
    forceUpdateWareDetail.subscribe(this.forceUpdateWareDetail);
  },
  beforeDestroy() {
    forceUpdateWareDetail.unsubscribe();
  },
  methods: {
    searchImage(){
      const {description, article, trade_mark} = this.current
      const search = [description, article, trade_mark].join(" ").trim()
      return googleImage(search)
    },
    // Принудительное обновление товара (т.н артикула)
    // применяется в случае когда фокус смещается из всего товара
    // вследствие чего возникает необходимость обновить сначала дочерние компоненты, а потом родительские
    // тк описание в родителе зависит от данных элементов.
    replaceFloatCost({value, prop}){
      if(value.includes('.')){
        let result = ""
        const float = Number.parseFloat(value)
        result = float.toFixed(2).replace(/0*$/,"").replace(/\.*$/,"")
        return this.current[prop] = result
      }
      return this.current[prop] = value
    },
    replaceFloatNumbers({value, prop}){
      if(value.includes('.')){ //Если не целое число
        let result = ""
        const float = Number.parseFloat(value)
        if(value >= 0.001){
          result = float.toFixed(3).replace(/0*$/,"").replace(/\.*$/,"") // Убираем лишние нули в конце и если нужно точку
        }else{
          result = float.toFixed(6).replace(/0*$/,"").replace(/\.*$/,"")
        }
        return this.current[prop] = result
      }
      return this.current[prop] = value
    },
    remove() {
      this.$emit("delete", this.current);
    },
    addGoodItem(){
      if(this.hasChanges){
        this.uploadData().then(() => {
          this.updated()
          this.$emit('addGoodItem')
        }).catch(() => {
          this.$snackbar({text:'Ошибка', color:'red', top:false})
        })
      }else{
        this.$emit('addGoodItem')
      }
    },
    copyGoodItem(){
      if(this.hasChanges){
        this.uploadData().then(() => {
          this.updated()
          this.$emit('copyGoodItem', this.current.id)
        }).catch(() => {
          this.$snackbar({text:'Ошибка', color:'red', top:false})
        })
      }
      this.$emit('copyGoodItem', this.current.id)
    },
    forceUpdateWareDetail({ id }) {
      if (id === this.current.ware_id) {
        if (this.hasChanges && this.fields_been_visited) {
          // Создаем промис и отправляем его в родительский компонент
          const promise = new Promise((resolve, reject) => {
            this.setHasChangesFalse();
            const payload = convertEmptyStringsToNull(this.current);
            this.$store
              .dispatch("epi/updateWareDetail", payload)
              .then((res) => resolve(res))
              .catch((err) => reject(err));
          })
          addPromise.trigger({id:this.current.ware_id, promise, type:"ware"})
        }
      }
    },
    uploadData() {
      // Ставим hasChanges =  false чтобы запрос не дублировался
      // тк ответ еще не пришел с сервера
      const payload = convertEmptyStringsToNull(this.current);
      return this.$store
        .dispatch("epi/updateWareDetail", payload)
        .then((res) => {
          wareDetailModified.trigger({
            index: this.wareIndex,
            action: "update",
          });
          return res;
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    setFields() {
      for (let key in this.item) {
        if (key in this.current) {
          this.current[key] = this.item[key];
        }
      }
    },
    updated(){
      this.hasChanges = false
      this.fields_been_visited = false
    }

  },
};
</script>
<style scoped>
tr {
  background-color: #ededed !important;
}
td {
  padding: 0 5px 0 5px !important;
  border-bottom: transparent !important;
}
tr:last-child {
  padding: 0 8px 0 5px !important;
}
</style>
