<template>
  <div v-click-outside="checkOrderAndUpdate">
    <v-container fluid>
      <div
        id="epi-presented-documents"
        class="statistics-box position-relative epi-documents-list"
        :class="{'blocked-box' : disabledView}"
      >
        <v-col
          cols="12"
        >
          <v-row>
            <v-col
              cols="2"
              class="pb-0"
            >
              <span class="font-weight-bold">
                44 Документы ({{ presented_documents.length }})
                <v-menu offset-x>
                  <template #activator="{ on }">
                    <v-icon
                      tabindex="-1"
                      dense
                      class="mx-1"
                      v-on="on"
                    >mdi-tune</v-icon>
                  </template>
                  <v-list>
                    <v-list-item
                      :disabled="!selectedDocs.length"
                      @click="deleteDocumentArr"
                    >
                      Удалить выбранные документы
                    </v-list-item>
                    <v-list-item
                      :disabled="!selectedDocs.length"
                      @click="clearRangeForSelected"
                    >
                      Очистить поле "для товаров" у выбранных
                    </v-list-item>
                    <v-list-item @click="hideTooltips = !hideTooltips">
                      {{ hideTooltips ? "Включить" : "Отключить" }} подсказки
                    </v-list-item>
                  </v-list>
                </v-menu>
              </span>
            </v-col>
            <v-col
              cols="5"
              class="pb-0"
              style="z-index: 3"
            >
              <v-checkbox
                v-model="showAllDocs"
                tabindex="-1"
                dense
                color="#5CB7B1"
              >
                <template #label>
                  <label
                    class="ma-0"
                    style="font-size: 12px"
                  >Показать все документы</label>
                </template>
              </v-checkbox>
            </v-col>
            <v-col
              cols="5"
              class="d-flex justify-end"
            >
              <v-row class="align-center justify-end px-3">
                <span
                  class="mr-2"
                  style="font-size: 12px"
                >
                  Для всех товаров
                </span>
                <v-switch
                  v-model="forEditedGoods"
                  tabindex="-1"
                  hide-details="auto"
                  dense
                  color="#5CB7B1"
                >
                  <template #label>
                    <label
                      style="font-size: 12px"
                    >Для редактируемого товара</label>
                  </template>
                </v-switch>
              </v-row>

              <v-btn
                tabindex="-1"
                min-width="36px"
                max-width="36px"
                height="36px"
                color="#5CB7B1"
                class="ml-3 px-0"
                @click="togglePin"
              >
                <v-icon>
                  {{ pinned.includes(pinName) ? "mdi-pin-off" : "mdi-pin" }}
                </v-icon>
              </v-btn>

              <v-btn
                tabindex="-1"
                min-height="0px"
                text
                min-width="32px"
                class="ml-3 pa-3"
                @click="collapsed = !collapsed"
              >
                <v-icon>
                  {{ collapsed ? "mdi-chevron-down" : "mdi-chevron-up" }}
                </v-icon>
              </v-btn>
            </v-col>
            <ul
              v-show="collapsed"
              class="ma-0"
              style="width:100%;list-style:none;"
              :style="presented_documents.length > 4 ? 'columns:2;' : ''"
            >
              <single-document-collapsed
                v-for="(doc, idx) in collapsedDocs"
                :ref="'doc_collapsed_' + idx"
                :key="'doc_collapsed_' + idx"
                :item="doc"
                :index="idx"
              />
            </ul>
            <v-col
              v-show="!collapsed"
              cols="12"
            >
              <v-btn
                v-if="!presented_documents.length"
                class="document-add elevation-0 px-15"
                color="transparent"
                @click="addNewDocument"
              >
                Добавить <v-icon>mdi-plus</v-icon>
              </v-btn>
              <div class="d-flex px-1">
                <div class="header__select">
                  <v-checkbox
                    tabindex="-1"
                    dense
                    hide-details="true"
                    color="#5CB7B1"
                    :value="isAllSelected"
                    @change="checkAll"
                  />
                </div>
                <div class="header__sort">
                  <small>№ п/п</small>
                </div>
                <div class="header__code">
                  <small>Код</small>
                </div>
                <div class="header__number">
                  <small>Номер документа</small>
                </div>
                <div class="header__date">
                  <small>Дата</small>
                </div>
                <div class="header__range">
                  <small>Для товаров</small>
                </div>
              </div>
              <v-virtual-scroll
                bench="10"
                :height="showAllDocs ? tableHeight : '300px'"
                item-height="50px"
                :items="presented_documents"
                class="drop-zone"
                @drop="onDrop($event, 1)"
                @dragover.prevent
                @dragenter.prevent
              >
                <template #default="{ index, item }">
                  <single-document
                    :ref="'doc_' + index"
                    :key="'doc_' + index"
                    :item="item"
                    :index="index"
                    :for-single-item="forEditedGoods"
                    :draggable.sync="draggable"
                    :hide-tooltips="hideTooltips"
                    :selected-docs="selectedDocs"
                    :presented-documents="presented_documents"
                    :control="controlPath"
                    @select="onSelect"
                    @delete="deleteDocument"
                    @addNewDoc="addNewDocument"
                  />
                </template>
              </v-virtual-scroll>
            </v-col>
          </v-row>
        </v-col>
      </div>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import SingleDocument from "@/components/epi/form/documents/single-document.vue";
import { convertEmptyStringsToNull } from "@/helpers/objects";
import { rangeToArray } from "@/helpers/inputs";
import { presented_documents as onPresentedDocuments } from "@/events/statistics/control";
import highlightFieldsParent from "@/mixins/control/highlight-fields-parent";
import SingleDocumentCollapsed from "@/components/statistics/documents/single-document-collapsed.vue";
import DocumentsDropZoneMixin from "@/mixins/draggable/documents-drop-zone.mixin";

export default {
  components: {
    SingleDocumentCollapsed,
    SingleDocument,
  },
  mixins: [highlightFieldsParent, DocumentsDropZoneMixin],
  data: () => ({
    pinName: "documents",
    selectedDocs: [],
    showAllDocs: false,
    hideTooltips: true,
    forEditedGoods: false,
    date: null,
    menu: false,
    pin: false,
    presented_documents: [],
    collapsed: false,
    controlSettings: {
      controlBlockSelector: ".epi-documents-list",
      controlElementSelector: ".epi-documents-list .v-virtual-scroll",
    },
  }),
  computed: {
    ...mapGetters({
      selected: "epi/getSelected",
      editableWare: "epi/getEditableWare",
      pinned: "ui/getPinned",
      shipmentIndex:'epi/getShipmentIndex',
      disabledView:"epi/getVisibility"
    }),
    collapsedDocs() {
      if (!this.showAllDocs) {
        return this.presented_documents.slice(0, 10);
      } else {
        return this.presented_documents;
      }
    },
    isAllSelected() {
      return (
        this.selectedDocs.length &&
        this.selectedDocs.length === this.presented_documents.length
      );
    },
    tableHeight() {
      return this.presented_documents.length * 50;
    },
  },
  watch: {
    selected: {
      handler() {
        this.showDocumentsForSelectedWare();
      },
      deep: true,
      immediate: true,
    },
    shipmentIndex(){
      this.setFields()
    },
    editableWare() {
      this.showDocumentsForSelectedWare();
    },
    forEditedGoods(nv) {
      this.draggable = nv !== true;
      this.showDocumentsForSelectedWare();
    },
  },
  created() {
    onPresentedDocuments.subscribe(this.onControl);
  },
  beforeDestroy() {
    onPresentedDocuments.unsubscribe();
  },
  methods: {
    togglePin() {
      this.$store.commit("ui/TOGGLE_PIN", this.pinName);
    },
    checkAll() {
      if (this.selectedDocs.length === this.presented_documents.length) {
        this.selectedDocs = [];
      } else {
        this.selectedDocs = this.presented_documents.map((i) => i.id);
      }
    },
    onSelect(id) {
      if (this.selectedDocs.includes(id)) {
        this.selectedDocs = this.selectedDocs.filter((i) => i !== id);
      } else {
        this.selectedDocs.push(id);
      }
    },
    getDocumentsForCurrentWare(documents, index){
      return documents.filter(
        (doc) =>
          rangeToArray(doc.ware_range).includes(index) ||
              doc.ware_range === null
      );
    },
    showDocumentsForSelectedWare() {
      if (this.forEditedGoods) {
        const presented_documents = this.getPresentedDocuments()
        const { index } = this.editableWare;
        const idx = index + 1;
        this.presented_documents = this.getDocumentsForCurrentWare(presented_documents, idx)
      } else {
        this.setFields();
      }
    },
    getPresentedDocuments(){
      return this.selected?.ware_shipments[this.shipmentIndex].presented_documents || [];
    },
    setFields() {
      this.presented_documents = this.getPresentedDocuments()
    },
    addNewDocument() {
      if (this.orderChanged) {
        this.checkOrderAndUpdate().then(() => {
          this.createDocument();
        });
      } else {
        this.createDocument();
      }
    },
    createDocument() {
      this.$store.dispatch("epi/addShipmentDocument");
    },
    deleteDocument(payload) {
      if (this.orderChanged) {
        this.checkOrderAndUpdate().then(() => {
          this.$store.dispatch("epi/deleteShipmentDocument", payload).catch(() => this.$error());
        })
      } else{
        this.$store.dispatch("epi/deleteShipmentDocument", payload)
          .catch(() => this.$error());
      }
    },
    clearRangeForSelected() {
      const documents = this.presented_documents
        .filter((item) => this.selectedDocs.includes(item.id))
        .map((item) => {
          return {
            ...item,
            ware_range: "",
          };
        });
      const payload = convertEmptyStringsToNull(documents);
      this.$store.dispatch("epi/updateShipmentDocument", { payload }).catch(() => this.$error());
    },
    deleteDocumentArr() {
      this.$store
        .dispatch("epi/deleteDocumentArr", this.selectedDocs)
        .catch(() => this.$error());
      this.unsetSelected();
      this.checkOrderAndUpdate();
    },
    updateSortIndexes(presentedDocuments){
      return presentedDocuments.map((document, idx) => {
        return {
          ...document,
          sort_index: idx + 1,
        };
      });
    },
    checkOrderAndUpdate() {
      if (this.orderChanged) {
        const docs = this.updateSortIndexes(this.presented_documents)
        const payload = docs.map((doc) => convertEmptyStringsToNull(doc));
        return this.$store
          .dispatch("epi/updateShipmentDocument", { payload })
          .then(() => {
            this.orderChanged = false;
          })
          .catch(() => this.$error());
      }
    },
    unsetSelected() {
      this.selectedDocs = [];
    },
  },
};
</script>
<style scoped>
.header__sort {
  width: 60px !important;
}
.header__code {
  width: 180px !important;
  padding: 0 10px 0 0;
}
.header__number {
  width: 300px !important;
  padding: 0 10px 0 0;
}
.header__date {
  width: 160px !important;
}
.header__range {
  width: 250px !important;
  padding: 0 10px;
}

.v-data-table__wrapper {
  padding-bottom: 0 !important;
}

.document-add {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translateX(50%);
  z-index: 1;
}
</style>
