<template>
  <section
    class="pb-16 mx-auto sd-main"
    style="width: 1440px"
  >
    <pinned-template
      :tabs="tabs"
    />

    <toolbox />
    <main-info />
    <transport />
    <reloads />
    <carrier />
    <carrier-e-e-u />
    <driver />

    <principal />
    <filler />


    <shipments />
    <warranty />

    <catalogs />
    <shared-buffer />
    <epi-copy-field-modal />
    <copy-field-to-all-modal />
    <side-menu :items="menuItems" />
    <distinct-division-notification />
    <forbidden-carrier-notification />
  </section>
</template>

<script>
import PinnedTemplate from '@/components/shared/pinned-template.vue'
import Toolbox from "@/components/epi/form/toolbox.vue";
import MainInfo from "@/components/epi/form/main-info.vue";
import Catalogs from "@/components/catalogs/modals/resolver.vue";
import offsetMixin from '@/mixins/offset.mixin.js'
import Filler from "@/components/epi/form/filler.vue";
import Carrier from "@/components/epi/form/carrier.vue";
import CarrierEEU from "@/components/epi/form/carriereeu.vue";
import Reloads from "@/components/epi/form/reloads/reloads.vue";
import Driver from "@/components/epi/form/driver.vue";
import Shipments from "@/components/epi/form/shipments/shipments.vue";
import Warranty from "@/components/epi/form/warranty/warranty.vue";
import SharedBuffer from "@/components/shared/shared-buffer.vue";
import Transport from "@/components/epi/form/transport/transport.vue"
import CopyFieldToAllModal from "@/components/documents/copy-field-to-all-modal.vue";
import Principal from "@/components/epi/form/principal.vue";
import SideMenu from "@/components/shared/side-menu.vue";
import WaresScroll from "@/components/shared/wares-scroll.vue";
import DistinctDivisionNotification from "@/components/documents/warnings/distinct-division-notification.vue";
import {mapGetters} from "vuex";
import {setDeclarationTitle} from "@/helpers/text";
import EpiCopyFieldModal from "@/components/epi/form/goods/epi-copy-field-modal.vue";
import ForbiddenCarrierNotification from "@/components/documents/warnings/forbidden-carrier-notification.vue";
export default{
  components: {
    ForbiddenCarrierNotification,
    EpiCopyFieldModal,
    DistinctDivisionNotification,
    SideMenu,
    Principal,
    CarrierEEU,
    CopyFieldToAllModal,
    Transport,
    SharedBuffer,
    Shipments,
    Carrier, Filler,
    PinnedTemplate,
    Toolbox,
    MainInfo,
    Reloads,
    Warranty,
    Catalogs,
    Driver,
  },
  mixins:[offsetMixin],
  data:() => ({
    menuItems:[
      {
        name:"Основная информация",
        id:"epi-main-info"
      },
      {
        name:"Транспорт",
        id:"epi-about-transport"
      },
      {
        name:"Перегрузки",
        id:"epi-reloads"
      },
      {
        name:"Перевозчик",
        id:"epi-carrier"
      },
      {
        name:"Перевозчик ЕЭC",
        id:"epi-carrier-eeu"
      },
      {
        name:"Водитель",
        id:"epi-driver"
      },
      {
        name:"Декларант таможенной процедуры таможенного транзита",
        id:"epi-principal"
      },
      {
        name:"Лицо, предоставившее предварительную информацию",
        id:"epi-filler"
      },
      null,
      {
        name: "Общие характеристики товара",
        id:"epi-goods-character"
      },
      {
        name:"Орган назначения",
        id:"epi-destination-customs"
      },
      {
        name:"Отправитель",
        id:"epi-sender"
      },
      {
        name:"Получатель",
        id:"epi-recipient"
      },
      {
        name:"Документы",
        id:"epi-presented-documents"
      },
      {
        name:"Товары",
        id:"epi-wares",
        component:WaresScroll,
      },
      null,
      {
        name:"Гарантии",
        id:"epi-guarantee"
      }
    ],
    tabs:[
      {
        name:'controls',
        component:() => import('@/components/epi/form/controls/common-errors.vue')
      },
      {
        name:'documents',
        component:() => import('@/components/epi/form/documents/documents.vue')
      },
      {
        name:'goods-character',
        component:() => import('@/components/epi/form/goods-character.vue')
      },
      {
        name:'payment-errors',
        component: () => import('@/components/epi/form/controls/payment-errors.vue'),
      }
    ]
  }),
  computed: {
    ...mapGetters({
      selected: "epi/getSelected",
    }),
  },
  watch: {
    selected: {
      handler(epi) {
        const {remark, out_number: outNumber} = epi?.about_declaration ?? {}
        setDeclarationTitle({
          outNumber,
          remark,
          prefix: "ЭПИ"
        })
      },
      deep: true,
    },
  },
  created(){
    this.$store.dispatch("catalogs/fetchAll")
    this.$store.dispatch('epi/fetchNsiTransportMarks')
    this.$store.dispatch('epi/fetchNsiAeos')
    const id = this.$route.params.id
    this.$store.dispatch("epi/getDocumentById", id).catch(err => {
      if(err.response.status === 403){
        this.$router.push('/403')
      }
    });

  },
}
</script>
