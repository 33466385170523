<template>
  <v-col cols="12">
    <div
      class="statistics-box pa-4"
      :class="{'blocked-box' : disabledView}"
    >
      <v-row
        :id="block_id"
        v-click-outside="triggerOnFocus"
      >
        <v-col
          cols="12"
        >
          <v-row>
            <v-col
              cols="9"
              class="pb-0"
            >
              <div class="font-weight-bold d-flex align-center mt-2">
                Перевозчик по ЕЭС
                <v-menu offset-x>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      class="mx-1 mr-4"
                      tabindex="-1"
                      small
                      dense
                      v-bind="attrs"
                      v-on="on"
                      @click="onMenuOpen"
                    >
                      mdi-tune
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item> Поиск в справочнике </v-list-item>
                    <v-list-item @click="show = true">
                      Сохранить перевозчика в справочник
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-col>
            <v-col
              cols="3"
              class="d-flex justify-end align-center"
            >
              <v-autocomplete
                v-model="search"
                class="ml-16"
                :loading="loading"
                :readonly="loading"
                :items="carriers"
                item-text="own_name"
                :filter="filterBySearchField"
                placeholder="Поиск в справочнике"
                auto-select-first
                return-object
                append-icon="mdi-magnify"
                background-color="white"
                dense
                outlined
                hide-details="true"
                tabindex="-1"
                @update:search-input="fetchCarriers"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="1"
              class="pb-0"
            >
              <label>
                Страна
                <v-autocomplete
                  ref="country_letter"
                  v-model="carrier_eeu_json.country_letter"
                  auto-select-first
                  outlined
                  :filter="filterBySearchField"
                  dense
                  hide-details="auto"
                  background-color="white"
                  :items="countries"
                  item-text="letterCode"
                  item-value="letterCode"
                />
              </label>
            </v-col>
            <v-col
              cols="2"
              class="pb-0"
            >
              <label>
                Уникальный номер
                <v-text-field
                  ref="carrier_eeu_inn"
                  v-model="carrier_eeu.carrier_eeu_inn"
                  class="mr-1"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                  @change="checkIsForbiddenCarrierEEU"
                />
              </label>
            </v-col>
            <v-col
              cols="3"
              class="pb-0"
            >
              <label>
                Наименование
                <v-text-field
                  ref="carrier_eeu_name"
                  v-model="carrier_eeu.carrier_eeu_name"
                  class="mr-1"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col cols="6" />
            <v-col
              cols="1"
            >
              <label>
                Индекс
                <v-text-field
                  ref="postal_index"
                  v-model="carrier_eeu_json.postal_index"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="3"
            >
              <label>
                Область/Район
                <v-text-field
                  ref="region"
                  v-model="carrier_eeu_json.region"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="2"
            >
              <label>
                Город/Деревня
                <v-text-field
                  ref="city"
                  v-model="carrier_eeu_json.city"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="3"
            >
              <label>
                Улица
                <v-text-field
                  ref="street_house"
                  v-model="carrier_eeu_json.street_house"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="1"
            >
              <label>
                Дом
                <v-text-field
                  ref="house"
                  v-model="carrier_eeu_json.house"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="2"
            >
              <label>
                Номер помещения
                <v-text-field
                  ref="room"
                  v-model="carrier_eeu_json.room"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <archive
      :show.sync="show"
      entity="carrier_eeu"
      @save="saveToArchive"
    />
  </v-col>
</template>

<script>
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import { getCountryNameByCode } from "@/helpers/catalogs";
import { mapGetters } from "vuex";
import Archive from "@/components/epi/form/contragents/archive.vue";
import { highlightField } from "@/helpers/control";
import { carrier as onCarrierEEU } from "@/events/statistics/control";
import {addSearchFieldToCarriers} from "@/components/epi/form/carriers";
import {checkIsForbidden} from "@/helpers/risks";

export default {
  components: { Archive },
  mixins: [filterBySearchFieldMixin, blockAutoUpdate],
  data: () => ({
    block_id: "epi-carrier-eeu",
    promises:[],
    collapsedDriver: false,
    carrier_eeu: {
      declaration_id: null,
      carrier_eeu_inn: "",
      carrier_eeu_name: "",
    },
    carrier_eeu_json: {
      country_letter: "",
      country_name: "",
      postal_index: "",
      region: "",
      city: "",
      street_house: "",
      house: "",
      room: "",
    },
    // поиск в справочнике
    search: null,
    loading: false,
    carriers: [],
    show: false,
  }),
  computed: {
    ...mapGetters({
      user: "auth/getUser",
      selected: "epi/getSelected",
      countries: "catalogs/getNsiCountries",
      disabledView:"epi/getVisibility"
    }),
    divisionId() {
      return this.user?.user?.activeDivisionId;
    },
  },
  watch: {
    search(nv) {
      if (nv && Object.keys(nv).length) {
        this.updateFieldsOnSearch(nv);
        this.search = null;
        this.carriers = [];
        this.forceUpload();
      }
    },
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
    },
  },
  created() {
    onCarrierEEU.subscribe(this.highlightField);
  },
  beforeDestroy() {
    onCarrierEEU.unsubscribe();
  },
  methods: {
    highlightField,
    checkIsForbiddenCarrierEEU(identity){
      checkIsForbidden.call(this, {
        identity,
        promises: this.promises,
        dataModel:"carrier_eeu.carrier_eeu_inn",
      })
    },
    saveToArchive(own_name) {
      const payload = {
        id: null,
        own_name: own_name,
        division_id: this.divisionId,
        root_parent_division_id: null,
        name: this.carrier_eeu.carrier_eeu_name,
        inn: this.carrier_eeu.carrier_eeu_inn,
        country_letter: this.carrier_eeu_json.country_letter,
        country_name: this.carrier_eeu_json.country_name,
        region: this.carrier_eeu_json.region,
        city: this.carrier_eeu_json.city,
        street: this.carrier_eeu_json.street_house,
        house: this.carrier_eeu_json.house,
        room: this.carrier_eeu_json.room,
        postal_index: this.carrier_eeu_json.postal_index,
        contacts_json: [],
        branch_name: null,
        branch_inn: null,
        branch_country_letter: null,
        branch_country_name: null,
        branch_region: null,
        branch_city: null,
        branch_street: null,
        branch_house: null,
        branch_room: null,
        branch_postal_index: null,
        branch_contacts_json: [],
      };

      this.$store
        .dispatch("catalogs/saveCarrier", payload)
        .then(() => this.$info("Справочник обновлен"))
        .catch((err) =>
          err.response.status === 400 ? this.$error(err.response.data ?? "Ошибка") : this.$error()
        );
    },
    updateFieldsOnSearch(item) {
      this.carrier_eeu.carrier_eeu_inn = item.inn;
      this.carrier_eeu.carrier_eeu_name = item.name;

      Object.keys(this.carrier_eeu_json).forEach((key) => {
        if (key in item) {
          this.carrier_eeu_json[key] = item[key];
        }
      });
      this.carrier_eeu_json.street_house = item.street;
    },
    setFields() {
      const carrier_eeu = this.selected.carrier_eeu;
      const carrier_eeu_json = this.selected.carrier_eeu.carrier_eeu_json;

      Object.keys(this.carrier_eeu).forEach((key) => {
        if (key in carrier_eeu) {
          this.carrier_eeu[key] = carrier_eeu[key];
        }
      });

      Object.keys(this.carrier_eeu_json).forEach((key) => {
        if (key in carrier_eeu_json) {
          this.carrier_eeu_json[key] = carrier_eeu_json[key];
        }
      });

    },
    forceUpload() {
      return this.uploadData()
        .then((res) => {
          this.$success("Обновлено [Перевозчик]")
          return res;
        })
        .catch((err) => {
          this.$error()
          return Promise.reject(err);
        });
    },
    onMenuOpen() {
      if (this.hasChanges && this.fields_been_visited) {
        this.forceUpload().then(() => {
          this.fields_been_visited = false;
          this.hasChanges = false;
        });
      }
    },
    uploadData() {
      return Promise.all(this.promises).then(() => {
        const carrier_eeu = this.convertEmptyStringsToNull({
          ...this.carrier_eeu,
          carrier_eeu_json: {
            ...this.carrier_eeu_json,
            country_name:
                getCountryNameByCode(this.countries, this.carrier_eeu_json.country_letter)
          },
        });
        return this.$store.dispatch("epi/uploadBlockData", {
          name: "carrier_eeu",
          value: carrier_eeu,
        });
      })
    },
    fetchCarriers(value) {
      if (value && value.length > 3) {
        this.loading = true;
        this.$store
          .dispatch("catalogs/searchCarrier", {
            value,
            divisionId: this.divisionId,
          })
          .then((res) => {
            this.carriers = addSearchFieldToCarriers(res.data)
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
