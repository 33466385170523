<template>
  <v-container
    :id="block_id"
    v-click-outside="triggerOnFocus"
    fluid
  >
    <v-col cols="12">
      <v-row
        class="statistics-box pb-4"
        :class="{'blocked-box' : disabledView}"
      >
        <v-col
          cols="8"
          class="d-flex align-center"
        >
          <span class="font-weight-bold">
            Орган назначения
          </span>
        </v-col>
        <v-col
          cols="4"
          class="d-flex justify-end align-center"
        >
          <div class="d-flex justify-end">
            <v-btn
              min-width="32px"
              class="ml-2"
              text
              @click="collapsed = !collapsed"
            >
              <v-icon>
                {{ collapsed ? "mdi-chevron-down" : "mdi-chevron-up" }}
              </v-icon>
            </v-btn>
          </div>
        </v-col>
        <v-col
          cols="12"
          class="pb-0"
        >
          <v-row>
            <v-col cols="4">
              <label> Код </label>
              <v-autocomplete
                ref="destination_customs_code"
                v-model="destination_customs_json.destination_customs_code"
                auto-select-first
                outlined
                dense
                hide-details="auto"
                background-color="white"
                class="mr-1"
                item-text="text"
                item-value="code"
                :items="customsPoints"
                :filter="filterBySearchField"
              />
            </v-col>
            <v-col
              offset="2"
              cols="6"
            >
              <v-textarea
                :value="address"
                readonly
                rows="2"
                outlined
                dense
                hide-details="auto"
                background-color="grey lighten-2"
              />
            </v-col>


            <v-col
              v-show="!collapsed"
              cols="12"
              class="align-center"
            >
              <v-row>
                <v-col cols="6">
                  <span>Дополнительно</span>
                  <v-row>
                    <v-col>
                      <label>
                        Номер ЗТК
                        <v-text-field
                          ref="destination_customs_id"
                          v-model="destination_customs_json.destination_customs_id"
                          class="mr-1"
                          outlined
                          dense
                          hide-details="auto"
                          background-color="white"
                        />
                      </label>
                    </v-col>
                    <v-col>
                      <label>
                        Ж/д станция
                        <v-autocomplete
                          ref="destination_customs_station"
                          v-model="destination_customs_json.destination_customs_station"
                          auto-select-first
                          outlined
                          dense
                          hide-details="auto"
                          background-color="white"
                          class="mr-1"
                          item-text="search"
                          item-value="code"
                          :items="railwayStations"
                        />
                      </label>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6">
                  <span>Сведения об уполномоченном экономическом  операторе </span>
                  <v-row>
                    <v-col cols="4">
                      <label>
                        Страна
                        <v-menu offset-x>
                          <template #activator="{ on, attrs }">
                            <v-icon
                              class="mx-1"
                              tabindex="-1"
                              small
                              dense
                              v-bind="attrs"
                              v-on="on"
                            >mdi-tune</v-icon>
                          </template>
                          <v-list>
                            <v-list-item> Поиск в справочнике </v-list-item>
                          </v-list>
                        </v-menu>
                        <v-autocomplete
                          ref="destination_customs_aeo_country_letter"
                          v-model="
                            destination_customs_json.destination_customs_aeo_country_letter
                          "
                          auto-select-first
                          outlined
                          dense
                          hide-details="auto"
                          background-color="white"
                          :items="countries"
                          item-text="text"
                          item-value="letterCode"
                          class="mr-1"
                          :filter="filterBySearchField"
                        />
                      </label>
                    </v-col>
                    <v-col cols="8">
                      <label>
                        Номер по реестру
                        <v-autocomplete
                          ref="destination_customs_aeo_reg_number"
                          v-model="destination_customs_json.destination_customs_aeo_reg_number"
                          auto-select-first
                          outlined
                          dense
                          hide-details="auto"
                          background-color="white"
                          class="mr-1"
                          :items="aeos"
                          item-value="licenseNumber"
                          item-text="licenseNumber"
                        />
                      </label>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import {getCountryNameByCode, getCustomsNameByCode} from "@/helpers/catalogs";
import { eventBus } from "@/bus.js";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import { highlightField } from "@/helpers/control";
import {destination_customs as onDestinationCustoms} from "@/events/statistics/control";

export default {
  mixins: [filterBySearchFieldMixin, blockAutoUpdate],
  data: () => ({
    block_id: "epi-destination-customs",
    collapsed:true,
    show: false,
    railwayStations: [],
    transit_procedure: "",
    destination_customs: {
      ware_shipment_id: null,
    },
    destination_customs_json:{
      destination_customs_code: null,
      destination_customs_id: null,
      destination_customs_station: null,
      destination_customs_aeo_reg_number: null,
      destination_customs_aeo_country_letter: null,
    }
  }),
  computed: {
    ...mapGetters({
      selected: "epi/getSelected",
      countries: "catalogs/getNsiCountries",
      customsPoints: "catalogs/getNsiCustomsPoints",
      aeos: "epi/getNsiAeos",
      shipmentIndex:'epi/getShipmentIndex',
      disabledView:"epi/getVisibility"
    }),
    address(){
      const item = this.customsPoints.find(i => i.code === this.destination_customs_json.destination_customs_code)
      return item?.address ?? ""
    },
    transitProcedure(){
      return this.selected.type_declaration.transit_procedure
    }
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
      immediate:true
    },
    shipmentIndex(){
      this.setFields()
    },
    item: {
      handler() {
        if (this.visited) this.updateEditableGoodsItem();
      },
      deep: true,
    },
  },
  created() {
    onDestinationCustoms.subscribe(this.onControl)
    this.fetchRailwayStations();
    eventBus.$on("clearCountryTd", () => {
      this.clearCountryTd();
    });
  },
  beforeDestroy() {
    eventBus.$off("clearCountryTd")
  },
  methods: {
    onControl(path){
      this.collapsed = false
      this.highlightField(path)
    },
    highlightField,
    fetchRailwayStations() {
      this.$store
        .dispatch("catalogs/fetchNsiRailwayStations")
        .then((res) => {
          this.railwayStations = res.data.list;
          this.railwayStations.forEach((item) => {
            item.search = `${item.code}-${item.name}`;
          });
        })
        .catch(() => {
          this.$snackbar({
            text: "Возникла ошибка при загрузке справочника",
            color: "red",
            top: false,
            right: false,
          });
        });
    },
    clearCountryTd() {
      this.destination_customs_json.destination_customs_country_letter = null;
      this.destination_customs_json.destination_customs_code = null
      this.uploadData();
    },
    setFields() {
      const {destination_customs} = this.selected.ware_shipments[this.shipmentIndex];
      const {destination_customs_json} = destination_customs;

      Object.keys(this.destination_customs).forEach((key) => {
        if (key in destination_customs) {
          this.destination_customs[key] = destination_customs[key];
        }
      });

      Object.keys(this.destination_customs_json).forEach((key) => {
        if (key in destination_customs_json) {
          this.destination_customs_json[key] = destination_customs_json[key];
        }
      });
    },
    uploadData() {
      const destination_customs = this.convertEmptyStringsToNull({
        ...this.destination_customs,
        destination_customs_json: {
          ...this.destination_customs_json,
          destination_customs_name:getCustomsNameByCode(
            this.customsPoints,
            this.destination_customs_json.destination_customs_code
          ),
          destination_customs_aeo_country_name: getCountryNameByCode(
            this.countries,
            this.destination_customs_json.destination_customs_aeo_country_letter
          ),
        },
      })

      return this.$store.dispatch("epi/uploadShipmentBlockData", {
        name: "destination_customs",
        value: destination_customs,
      });
    },
  },
};
</script>
