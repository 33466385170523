<template>
  <v-col cols="12">
    <div
      :id="block_id"
      v-click-outside="triggerOnFocus"
      class="statistics-box pa-4"
      :class="{'blocked-box' : disabledView}"
    >
      <v-row>
        <v-col
          cols="12"
        >
          <v-row>
            <v-col cols="5">
              <div class="font-weight-bold d-flex align-center mt-2">
                Лицо, предоставившее предварительную информацию
                <v-menu offset-x>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      class="mx-1"
                      tabindex="-1"
                      small
                      dense
                      v-bind="attrs"
                      v-on="on"
                      @click="onMenuOpen"
                    >
                      mdi-tune
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item disabled>
                      Поиск в справочнике
                    </v-list-item>
                    <v-list-item
                      disabled
                      @click="show = true"
                    >
                      Сохранить в справочник
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-col>
            <v-col align-self="end">
              <v-switch
                v-model="filler_json.carrier"
                tabindex="-1"
                label="Перевозчик"
                class="order-0 mx-2 pb-0"
                color="#5CB7B1"
                dense
                hide-details="auto"
                @change="onCarrierSwitch"
              />
            </v-col>
            <v-col
              cols="4"
              class="d-flex justify-end align-center"
            >
              <div class="d-flex justify-end">
                <v-btn
                  min-width="32px"
                  class="ml-2"
                  text
                  @click="collapsed = !collapsed"
                >
                  <v-icon>
                    {{ collapsed ? "mdi-chevron-down" : "mdi-chevron-up" }}
                  </v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row v-show="!collapsed">
            <v-col
              cols="6"
              align-self="end"
            >
              <v-row>
                <v-col
                  cols="2"
                >
                  <label>
                    Страна
                    <v-autocomplete
                      ref="country_letter"
                      v-model="filler_json.country_letter"
                      auto-select-first
                      outlined
                      dense
                      :disabled="disabled"
                      :background-color="disabled ? 'grey lighten-2' : 'white'"
                      hide-details="auto"
                      :items="countries"
                      item-text="letterCode"
                      :filter="filterBySearchField"
                      item-value="letterCode"
                    />
                  </label>
                </v-col>
                <v-col cols="4">
                  <label>Уникальный номер</label>
                  <v-text-field
                    ref="filler_inn"
                    v-model="filler.filler_inn"
                    :disabled="disabled"
                    :background-color="disabled ? 'grey lighten-2' : 'white'"
                    class="mr-1"
                    outlined
                    dense
                    hide-details="auto"
                  />
                </v-col>
                <v-col cols="6">
                  <label>Наименование</label>
                  <v-text-field
                    ref="filler_name"
                    v-model="filler.filler_name"
                    :disabled="disabled"
                    :background-color="disabled ? 'grey lighten-2' : 'white'"
                    class="mr-1"
                    outlined
                    dense
                    hide-details="auto"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col
              style="flex:15%; max-width: 15%"
            />
            <v-col
              cols="4"
              style="border: 1px solid black"
              class="rounded mx-2"
            >
              <div class="font-weight-bold d-flex align-center mt-2">
                Таможенный представитель
              </div>
              <v-row>
                <v-col cols="6">
                  <label for="">
                    УНП
                  </label>
                  <v-text-field
                    ref="customs_agent_unp"
                    v-model="filler_json.customs_agent_unp"
                    :disabled="disabled"
                    :background-color="disabled ? 'grey lighten-2' : 'white'"
                    class="mr-1"
                    outlined
                    dense
                    hide-details="auto"
                  />
                </v-col>
                <v-col>
                  <label>№ по реестру</label>
                  <v-autocomplete
                    ref="customs_agent_license_num"
                    v-model="filler_json.customs_agent_license_num"
                    :disabled="disabled"
                    :background-color="disabled ? 'grey lighten-2' : 'white'"
                    :items="agents"
                    item-text="licenseNumber"
                    item-value="licenseNumber"
                    class="mr-1"
                    outlined
                    dense
                    hide-details="auto"
                  />
                </v-col>
              </v-row>
            </v-col>

            <v-col
              cols="1"
              class="pb-0"
            >
              <label>
                Индекс
                <v-text-field
                  ref="postal_index"
                  v-model="filler_json.postal_index"
                  :disabled="disabled"
                  :background-color="disabled ? 'grey lighten-2' : 'white'"
                  outlined
                  dense
                  hide-details="auto"
                />
              </label>
            </v-col>
            <v-col
              cols="2"
              class="pb-0"
            >
              <label>
                Область/Район
                <v-text-field
                  ref="region"
                  v-model="filler_json.region"
                  :disabled="disabled"
                  :background-color="disabled ? 'grey lighten-2' : 'white'"
                  class="mr-1"
                  outlined
                  dense
                  hide-details="auto"
                />
              </label>
            </v-col>
            <v-col
              cols="3"
              class="pb-0"
            >
              <label>
                Город/Деревня
                <v-text-field
                  ref="city"
                  v-model="filler_json.city"
                  class="mr-1"
                  outlined
                  dense
                  hide-details="auto"
                  :disabled="disabled"
                  :background-color="disabled ? 'grey lighten-2' : 'white'"
                />
              </label>
            </v-col>
            <v-col
              cols="4"
            >
              <label>
                Улица
                <v-text-field
                  ref="street_house"
                  v-model="filler_json.street_house"
                  class="mr-1"
                  outlined
                  dense
                  hide-details="auto"
                  :disabled="disabled"
                  :background-color="disabled ? 'grey lighten-2' : 'white'"
                />
              </label>
            </v-col>
            <v-col
              cols="1"
            >
              <label>
                Дом
                <v-text-field
                  ref="house"
                  v-model="filler_json.house"
                  class="mr-1"
                  outlined
                  dense
                  hide-details="auto"
                  :disabled="disabled"
                  :background-color="disabled ? 'grey lighten-2' : 'white'"
                />
              </label>
            </v-col>
            <v-col
              cols="1"
            >
              <label>
                Помещение
                <v-text-field
                  ref="room"
                  v-model="filler_json.room"
                  class="mr-1"
                  outlined
                  dense
                  hide-details="auto"
                  :disabled="disabled"
                  :background-color="disabled ? 'grey lighten-2' : 'white'"
                />
              </label>
            </v-col>
          </v-row>
          <filler-collapsed
            v-if="collapsed"
            :value="filler"
            :json="filler_json"
            :contacts="[]"
          />
        </v-col>
      </v-row>
    </div>
    <archive
      :show.sync="show"
      entity="filler"
      @save="saveToArchive"
    />
  </v-col>
</template>

<script>
import {mapGetters} from 'vuex'
import {filterBySearchFieldMixin} from "@/mixins/catalogs"
import blockAutoUpdate from "@/mixins/block-auto-update.mixin"
import Archive from '@/components/transit/contragents/archive.vue'
import {convertEmptyStringsToNull} from '@/helpers/objects'
import {getCountryNameByCode} from "@/helpers/catalogs";
import FillerCollapsed from "@/components/transit/filler-collapsed.vue";
import {filler as onFiller} from "@/events/statistics/control";
import {highlightField} from "@/helpers/control";

export default {
  components: {FillerCollapsed, Archive},
  mixins: [filterBySearchFieldMixin, blockAutoUpdate],
  data: () => ({
    block_id: 'epi-filler',
    collapsed: false,
    filler: {
      filler_inn: null,
      filler_name: null,
      declaration_id: null,
    },
    filler_json: {
      carrier: null,
      country_letter: null,
      postal_index: null,
      region: null,
      city: null,
      street_house: null,
      house: null,
      room: null,
      customs_agent_license_num: null,
      customs_agent_unp: null
    },
    // поиск в справочнике
    show: false
  }),
  computed: {
    ...mapGetters({
      selected: 'epi/getSelected',
      countries: "catalogs/getNsiCountries",
      user: 'auth/getUser',
      agents: "catalogs/getNsiCustomsAgent",
      disabledView: "epi/getVisibility"
    }),
    divisionId() {
      return this.user?.user?.activeDivisionId;
    },
    disabled() {
      return !!this.filler_json.carrier
    }
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true
    },
    "filler_json.carrier": {
      handler(value) {
        this.collapsed = value
      }
    }
  },
  created() {
    onFiller.subscribe(this.onFillerControl)
  },
  beforeDestroy() {
    onFiller.unsubscribe()
  },
  methods: {
    highlightField,
    onFillerControl(payload) {
      console.log(payload)
      this.collapsed = false
      this.$nextTick(() => {
        this.highlightField(payload)
      })
    },
    resetFiller() {
      this.filler.filler_inn = null
      this.filler.filler_name = null
      Object.keys(this.filler_json).forEach(key => {
        this.filler_json[key] = null
      })
    },
    onCarrierSwitch(value) {
      if (value === false) return
      this.resetFiller()
      this.filler_json.carrier = true
    },
    saveToArchive() {
      // const payload = {
      //   id: null,
      //   own_name: own_name,
      //   division_id: this.divisionId,
      //   root_parent_division_id: null,
      //   name: this.principal.principal_name,
      //   inn: this.principal.principal_inn,
      //   country_letter: this.principal_json.country_letter,
      //   country_name: this.principal_json.country_name,
      //   region: this.principal_json.region,
      //   city: this.principal_json.city,
      //   street: this.principal_json.street_house,
      //   house: this.principal_json.house,
      //   room: this.principal_json.room,
      //   postal_index:  this.principal_json.postal_index,
      //   contacts_json: [], // TODO: поправить сохранение в справочник
      //   branch_name: this.principal.principal_branch_name,
      //   branch_inn: this.principal.principal_branch_inn,
      //   branch_country_letter: this.principal_json.branch_country_letter,
      //   branch_country_name: this.principal_json.branch_country_name,
      //   branch_region: this.principal_json.branch_region,
      //   branch_city: this.principal_json.branch_city,
      //   branch_street: this.principal_json.branch_street_house,
      //   branch_house: this.principal_json.branch_house,
      //   branch_room: this.principal_json.branch_room,
      //   branch_postal_index:  this.principal_json.branch_postal_index,
      //   branch_contacts_json: []
      // }
      //
      // this.$store.dispatch("catalogs/saveCarrier", payload).then(() => {
      //   this.$snackbar({
      //     text: "Справочник обновлен",
      //     color: "blue",
      //     top: true,
      //     right: true,
      //   });
      // }).catch(err => {
      //   if(err.response.status === 400){
      //     this.$snackbar({text: err.response.data || "Ошибка",color:"red", top:false, right: false});
      //   }else{
      //     this.$snackbar({text: "Ошибка",color:"red", top:false, right: false});
      //   }
      // });
    },
    setFields() {
      const filler = this.selected.filler
      const filler_json = filler.filler_json

      Object.keys(this.filler).forEach(key => {
        if (key in filler) {
          this.filler[key] = filler[key]
        }
      })

      Object.keys(this.filler_json).forEach(key => {
        if (key in filler_json) {
          this.filler_json[key] = filler_json[key]
        }
      })
    },
    forceUpload() {
      return this.uploadData().then((res) => {
        this.$snackbar({text: "Обновлено [Пред. информацию]", color: "green", top: false, right: false});
        return res
      }).catch((err) => {
        this.$snackbar({text: "Ошибка", color: "red", top: false, right: false});
        return Promise.reject(err)
      })
    },
    onMenuOpen() {
      if (this.hasChanges && this.fields_been_visited) {
        this.forceUpload().then(() => {
          this.fields_been_visited = false
          this.hasChanges = false
        })
      }
    },
    uploadData() {
      const filler = convertEmptyStringsToNull({
        ...this.filler,
        filler_json: {
          ...this.filler_json,
          country_name: getCountryNameByCode(this.countries, this.filler_json.country_letter)
        }
      });
      return this.$store.dispatch("epi/uploadBlockData", {
        name: "filler",
        value: filler,
      });
    },
  }
}
</script>
