<template>
  <v-row
    class="pt-6"
  >
    <v-col
      cols="12"
      class="pb-0"
    >
      <span
        class="font-weight-bold text-body-2"
      >
        <v-checkbox
          tabindex="-1"
          dense
          hide-details="true"
          color="#5CB7B1"
          :value="isAllSelected"
          class="float-left m-0"
          @change="checkAll"
        />
        <div class="pt-2">
          40 Предшествующий документ
          <v-menu offset-x>
            <template #activator="{ on, attrs }">
              <v-icon
                class="mx-1"
                tabindex="-1"
                small
                dense
                v-bind="attrs"
                v-on="on"
              >mdi-tune</v-icon>
            </template>
            <v-list>
              <v-list-item
                :disabled="!selectedDocs.length"
                @click="copyToAll('preceding_documents', selectedDocs)"
              >
                Применить ко всем товарам
              </v-list-item>
              <v-list-item
                :disabled="!selectedDocs.length"
                @click="deleteDocumentArr"
              > Удалить выбранные документы </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </span>
    </v-col>
    <v-col cols="12">
      <v-row
        v-if="preceding_documents.length"
        class="mb-2"
      >
        <v-col cols="12">
          <document
            v-for="(item, idx) in preceding_documents"
            :key="idx"
            :value="item"
            :index="idx"
            :documents="preceding_documents"
            :ware-index="index"
            :control="control"
            :selected-docs="selectedDocs"
            @select="onSelect"
            @update="onDocumentUpdate"
            @add="addDocument"
          />
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col
          cols="3"
          class="pr-1"
        >
          <v-btn
            dense
            class="elevation-0"
            @click="addDocument"
          >
            <v-icon>mdi-plus</v-icon> Добавить документ
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import Document from "./document.vue";
import cloneDeep from "lodash.clonedeep";
import HighlightFieldWareSector from "@/mixins/control/highlight-field-ware-sector";
import copyFieldMixin from "@/mixins/copy-field.mixin";
export default {
  components: { Document },
  mixins:[HighlightFieldWareSector, copyFieldMixin],
  props: {
    index: {
      required: true,
      type: Number,
    },
    info: {
      required: true,
      type: Object,
    },
    visited: {
      required: true,
      type: Boolean,
    },
    hasChanges: {
      required: true,
      type: Boolean,
    },
    control: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    preceding_documents: [],
    selectedDocs: [],
  }),
  computed: {
    ...mapGetters({
      selected: "epi/getSelected",
      doc_types: "catalogs/getNsiTypesOfDocument",
      shipmentIndex:'epi/getShipmentIndex'
    }),
    selectedWares(){
      return this.selected.ware_shipments[this.shipmentIndex]?.wares ?? []
    },
    isAllSelected() {
      return (
        this.selectedDocs.length &&
        this.selectedDocs.length === this.preceding_documents.length
      );
    },
  },
  watch: {
    selectedWares: {
      handler() {
        this.setFields();
      },
      immediate: true,
      deep: true,
    },
    preceding_documents: {
      handler() {
        if (this.visited) {
          this.updateEditableGoodsItem();
        }
      },
      deep: true,
    },
  },
  methods: {
    onSelect(id) {
      if (this.selectedDocs.includes(id)) {
        this.selectedDocs = this.selectedDocs.filter((i) => i !== id);
      } else {
        this.selectedDocs.push(id);
      }
    },
    checkAll() {
      if (this.selectedDocs.length === this.preceding_documents.length) {
        this.selectedDocs = [];
      } else {
        this.selectedDocs = this.preceding_documents.map((i) => i.id);
      }
    },
    setFields() {
      const wares = cloneDeep(
        this.selectedWares[this.index].preceding_documents
      );
      this.preceding_documents = wares || [];
    },
    updateEditableGoodsItem() {
      const preceding_documents = this.preceding_documents || [];
      this.$emit("update:info", { preceding_documents });
      this.readyToUpdate();
    },
    readyToUpdate() {
      this.$emit("update:visited", true);
      this.$emit("update:has-changes", true);
    },
    onDocumentUpdate({ index, value }) {
      this.preceding_documents.splice(index, 1, value);
      this.readyToUpdate();
    },
    addDocument() {
      const payload = {
        ware_id: this.info.id,
        sort_index: this.preceding_documents.length + 1,
      };
      this.$store
        .dispatch("epi/addPrecedingDocument", payload)
        .then((res) => {
          this.preceding_documents.push(res.data);
          this.readyToUpdate();
        })
        .catch(() => {
          this.$snackbar({ text: "Ошибка", color: "red", top: false });
        });
    },
    deleteDocumentArr() {
      this.$store
        .dispatch("epi/deletePrecedingDocument", this.selectedDocs)
        .then(() => {
          this.preceding_documents = this.preceding_documents.filter((i) => !this.selectedDocs.includes(i.id))
          this.selectedDocs = []
          this.readyToUpdate();
        })
        .catch(() => {
          this.$snackbar({
            text: "Ошибка",
            color: "red",
            top: false,
            right: false,
          });
        });
    },
  },
};
</script>
