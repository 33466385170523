<template>
  <v-col
    cols="12"
    class="goods-description__block"
  >
    <v-tabs
      v-model="tab"
      class="position-relative"
      style="z-index: 2"
    >
      <v-tab tabindex="-1">
        Описание товаров
      </v-tab>
      <v-tab
        :disabled="!!vehicleTextLength"
        tabindex="-1"
        class="ware-show-list-tab"
      >
        Добавить товары списком
      </v-tab>
      <v-tab
        :disabled="!!wareDetails.length"
        tabindex="-1"
        class="ware-show-list-tab"
      >
        Автомобиль
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item class="tab-grey">
        <v-textarea
          v-show="showTop"
          placeholder="Значения из списка"
          :rows="topRows"
          loading="false"
          no-resize
          :value="calculatedDescription"
          full-width
          background-color="white"
          hide-details="true"
          class="textarea-top"
          readonly
          :class="{
            'textarea-top-error': totalValue > 1000
          }"
          @click="openDetailTab"
        />
        <div class="goods-description__text-wrapper">
          <v-textarea
            :value="item.description"
            placeholder="Описание"
            :counter="1000"
            :counter-value="total"
            :rows="bottomRows"
            no-resize
            loading="false"
            full-width
            background-color="white"
            hide-details="auto"
            class="description-text"
            :class="{
              'textarea-bottom-dashed' : showTop,
              'textarea-bottom-solid': !showTop,
              'textarea-error': totalValue > 1000
            }"
            @input="(nv) => $emit('input', nv)"
          />
          <div
            v-show="!add_goods_by_list"
            class="goods-description__action"
          >
            <v-menu offset-y>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  tabindex="-1"
                  min-width="36px"
                  max-width="36px"
                  color="#5CB7B1"
                  v-on="on"
                >
                  <v-icon>mdi-tune</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="copyToAll('description', item.description )">
                  Применить ко всем товарам
                </v-list-item>
                <v-list-item @click="copy('description', item.description, 'apply')">
                  Применить к выбранным товарам
                </v-list-item>
                <v-list-item @click="googleImage(item.description)">
                  <v-list-item-title>
                    <v-icon>mdi-magnify</v-icon>
                    Поиск в Google
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </v-tab-item>
      <v-tab-item eager>
        <table-goods-list
          :ware-details="wareDetails"
          :show-quantity="showQuantity"
          :item="item"
          :index="index"
          @update="(nv) => (waresListText = nv)"
        />
      </v-tab-item>
      <v-tab-item eager>
        <vehicle :index="index" />
      </v-tab-item>
    </v-tabs-items>
  </v-col>
</template>
<script>
import {mapGetters} from 'vuex';
import TableGoodsList from "./table-goods-list.vue";
import ApplyParamsMixin from '@/mixins/apply-params.mixin'
import {filterBySearchFieldMixin} from "@/mixins/catalogs"
import {googleImage} from '@/helpers/text'
import copyFieldMixin from "@/mixins/copy-field.mixin";
import Vehicle from "@/components/epi/form/goods/description/vehicle.vue";
import {getVehicleText} from "@/helpers/ware-vehicle";

export default {
  name: "GoodsDescription",
  components: {Vehicle, TableGoodsList},
  mixins: [ApplyParamsMixin, filterBySearchFieldMixin, copyFieldMixin],
  props: {
    index: {
      type: Number,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    wareDetails: {
      type: Array,
      required: true,
    },
    showQuantity: {
      type: Boolean,
      required: true,
    }
  },
  data: () => ({
    waresListText: "",
    tab: 0,
    selectedTab: {},
    add_goods_by_list: false,
  }),
  computed: {
    ...mapGetters({
      selected: "epi/getSelected",
    }),
    showTop() {
      return !!this.calculatedDescription.length || false;
    },
    // Cчитаем ко-во строк в верхнем блоке и нижнем, чтобы в сумме была 100 символов. Примерно 125 на строку.
    topRows() {
      return Math.ceil(this.calculatedDescription.length / 125) || 0;
    },
    bottomRows() {
      return 8 - this.topRows;
    },
    totalValue() {
      return this.total()
    },
    vehicleTextLength() {
      return this.vehicleText.length
    },
    vehicleText() {
      const {ware_transport_json} = this.item
      return getVehicleText(ware_transport_json)
    },
    calculatedDescription() {
      if (this.vehicleText.length) {
        return this.vehicleText
      }
      return this.waresListText
    }
  },
  methods: {
    googleImage,
    openDetailTab() {
      this.tab = this.vehicleText.length ? 2 : 1
    },
    total() {
      if (this.vehicleText.length) {
        return this.calculatedDescription.length + (this.item?.description?.length || 0)
      } else if (this.wareDetails.length) {
        const descriptionWithSeparator = this.item?.description?.length ? this.item?.description?.length + 2 : 0
        return this.calculatedDescription.length + descriptionWithSeparator
      } else {
        return (this.item?.description?.length || 0)
      }
    },
  },
};
</script>
<style scoped>
.goods-description__block, .goods-description__text-wrapper {
  position: relative;
}

.goods-description__action {
  position: absolute;
  right: 1%;
  top: 20px;
  z-index: 2;
}

.textarea-top {
  border: 1px solid rgba(0, 0, 0, 0.8);
  border-bottom: transparent;
  border-radius: 4px 4px 0 0;
  margin: 10px 0 0 0;
  padding: 10px 50px 10px 10px;
}

.textarea-bottom-dashed {
  border: 1px solid rgba(0, 0, 0, 0.8);
  border-top: 1px dashed rgba(0, 0, 0, 0.8);
  border-radius: 0 0 4px 4px;
  margin: 0;
  padding: 10px 50px 10px 10px;
}

.textarea-bottom-solid {
  border: 1px solid rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  margin: 10px 0 0 0;
  padding: 10px 50px 10px 10px;
}

.textarea-top-error {
  border-top: 2px solid red;
  border-left: 2px solid red;
  border-right: 2px solid red;
}

.textarea-error {
  border: 2px solid red;
}
</style>
