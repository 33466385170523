<template>
  <v-row>
    <v-col>
      <span class="mr-2">{{ identity }}</span>

      <span class="mr-2">{{ name }}</span>

      <span class="mr-2">{{ address }}</span>
    </v-col>
  </v-row>
</template>
<script>

export default {
  props: {
    value: {
      required: true,
      type: Object
    },
    json: {
      required: true,
      type: Object
    },
  },
  computed: {
    identity() {
      return this.value.principal_inn
    },
    name() {
      const {principal_last_name, principal_first_name, principal_middle_name} = this.value
      return !this.individual ? this.value.principal_name : [principal_last_name, principal_first_name, principal_middle_name].join(' ')
    },
    address() {
      const {country_name, postal_index, region, city, house, street_house, room} = this.json
      return [
        country_name,
        postal_index,
        region,
        (city ? `г.${city}` : null),
        (street_house ? `ул.${street_house}` : null),
        (house ? `д.${house}` : null),
        (room ? `пом.${room}` : null),
      ].filter(i => ![null, ""].includes(i)).join(', ')
    }
  }
}
</script>