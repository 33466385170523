export default {
  data:() => ({
    draggable:false,
    orderChanged:false
  }),
  methods:{
    onDrop(evt) {
      const document = evt.dataTransfer.getData("document");
      if(!document) return; // Завершаем если нет ID
      const targetElementId =
              evt.target.parentNode.getAttribute("id") ||
              evt.target.closest("div.document__row").getAttribute("id");
      if (targetElementId) {
        const target_parts = targetElementId.split("-");
        const target_id = target_parts[target_parts.length - 1];
      
        if(Number.parseInt(target_id) > 0){ // Завершаем если нет ID
          const target_document = this.presented_documents.find((i) => i.id === Number.parseInt(target_id))
          const target_index = this.presented_documents.indexOf(target_document);
          const dragged_document = this.presented_documents.find(
            (i) => i.id === Number.parseInt(document)
          );
      
          const dragged_index =
                this.presented_documents.indexOf(dragged_document);
          if (target_index !== dragged_index) {
            if (target_index < dragged_index) {
              this.presented_documents.splice(target_index, 0, dragged_document);
              this.presented_documents.splice(dragged_index + 1, 1);
                  
            } else {
              this.presented_documents.splice(dragged_index, 1);
              this.presented_documents.splice(target_index, 0, dragged_document);
            }
            this.orderChanged = true;
          }
          this.checkOrderAndUpdate();
      
        }  
      }
      this.draggable = false;
      this.selectedDocs = []
    },
  }
}