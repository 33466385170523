<template>
  <div>
    <v-simple-table
      :id="`sd-ware-${index}-vendors`"
      class="bg-gray"
      fixed-header
    >
      <template #default>
        <thead>
          <tr>
            <th
              v-for="(header, idx) in goods_list_headers"
              :key="'goods_header_' + idx"
              class="text-left pl-2"
            >
              {{ header.text }}
              <span
                v-if="idx === goods_list_headers.length - 1 && !items.length"
              ><v-btn
                :disabled="disabledView"
                min-width="32px"
                max-width="32px"
                height="32px"
                color="#5CB7B1"
                class="ware-add-article elevation-0 my-1"
                @click="addGoodItem"
              ><v-icon>mdi-plus</v-icon></v-btn></span>
            </th>
          </tr>
        </thead>

        <tbody>
          <table-goods-item
            v-for="(element, idx) of items"
            :key="'item-' + idx"
            :item="element"
            :index="idx"
            :show-quantity="showQuantity"
            :goods-length="items.length"
            :ware-index="index"
            @update="onItemUpdate"
            @addGoodItem="addGoodItem"
            @delete="itemDeleted"
            @copyGoodItem="copyGoodItem"
          />
        </tbody>
      </template>
    </v-simple-table>

    <div class="d-flex pt-5 justify-end align-center bg-gray">
      <v-btn
        :disabled="disabledView"
        min-width="36px"
        height="36px"
        color="#5CB7B1"
        class="recalculate-total"
        @click="recalculateTotal"
      >
        <v-icon>mdi-reload</v-icon>Пересчитать
      </v-btn>
    </div>
  </div>
</template>
<script>
import TableGoodsItem from "./table-goods-item.vue";
import { wareDetailModified, updateGoodParameters } from "@/events/statistics";
import {waresToDescription} from '@/helpers/inputs'
import {mapGetters} from "vuex";
export default {
  name: "TableGoodsList",
  components: {
    TableGoodsItem,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    wareDetails: {
      type: Array,
      required: true,
    },
    showQuantity:{
      type: Boolean,
      required: true,
    }
  },
  data: () => ({
    goods_list_headers: [
      { text: "", value: "" },
      { text: "Наименование товара", value: "" },
      { text: "Артикул", value: "" },
      { text: "Товарный знак", value: "" },
      { text: "Кол-во", value: "" },
      { text: "Ед. изм.", value: "" },
      { text: "Нетто", value: "" },
      { text: "Нетто 1", value: "" },
      { text: "Брутто", value: "" },
      { text: "Стоимость", value: "" },
      { text: "Кол-во доп. ед. изм.", value: "" },
      { text: "", value: "" },
    ],
    items: [],
  }),
  computed:{
    ...mapGetters({
      disabledView:'epi/getVisibility'
    })
  },
  watch: {
    items: {
      handler() {
        this.returnDescriptionString();
      },
      deep: true,
    },
    wareDetails: {
      handler(nv) {
        this.items = [...nv];
      },
      deep: true,
    },
  },
  methods: {
    //  Cумматоры по полям. Обновляют блок с параметрами
    recalculateTotal() {
      const initial = {
        net_weight: 0,
        net_weight_no_pack: 0,
        gross_weight: 0,
        invoice_cost: 0,
        add_measure_unit_quantity:0
      };
      const total = this.items.reduce((prev, curr) => {
        const result = {};
        for (let key in prev) {
          result[key] =
            Number.parseFloat(prev[key] || 0) +
            Number.parseFloat(curr[key] || 0);
        }
        return result;
      }, initial);
      updateGoodParameters.trigger({
        id: this.item.id,
        payload: total,
      });
    },
    copyGoodItem(id){
      const newWareIndex = this.getNewIndex();
      this.$store.dispatch('epi/copyWareDetail', {
        id,
        index: newWareIndex
      })
        .then(() => {
          wareDetailModified.trigger({
            index: this.index,
            action: "add",
          });
          const id = `#epi-${this.index}-vendor-code-${
            this.items.length - 1
          }`;
          this.$scrollTo(id, 200, {
            force: true,
            container: `#sd-ware-${this.index}-vendors .v-data-table__wrapper`,
          });
        }).catch(() => {
          this.$snackbar({text:"Ошибка",color:"red", top:false, right: false});
        });
    },
    addGoodItem() {
      const newWareIndex = this.getNewIndex();
      this.$store
        .dispatch("epi/createWareDetail", {
          wareId: this.item.id,
          index: newWareIndex,
        })
        .then(() => {
          wareDetailModified.trigger({
            index: this.index,
            action: "add",
          });
          const id = `#epi-${this.index}-vendor-code-${
            this.items.length - 1
          }`;
          this.$scrollTo(id, 200, {
            force: true,
            container: `#sd-ware-${this.index}-vendors .v-data-table__wrapper`,
          });
        }).catch(() => {
          this.$snackbar({text:"Ошибка",color:"red", top:false, right: false});
        });
    },
    getNewIndex() {
      if (!this.items.length) return 1;
      const indexes = this.items.map((i) => i.sort_index);
      return Math.max(...indexes) + 1;
    },
    onItemUpdate(item) {
      const index = this.items.findIndex((i) => i.sort_index === item.sort_index);
      this.items.splice(index, 1, item);
      // Trigger watcher
    },
    itemDeleted(item) {
      this.$store
        .dispatch("epi/deleteWareDetail", { wareId: item.id })
        .then(() => {
          wareDetailModified.trigger({
            index: this.index,
            action: "delete",
          });
        });
    },
    // Фунция для объединения полей артикулов, для последующей подстановки в "Описание товара"
    returnDescriptionString() {
      this.$emit("update", waresToDescription(this.items));
    },
  },
};
</script>
<style scoped>
.bg-gray {
  background-color: #ededed !important;
}
thead tr th {
  padding: 0 auto 0 0 !important;
  background-color: #ededed !important;
  height: 28px !important;
}
</style>
