<template>
  <v-overlay :value="show">
    <v-card
      width="1300px"
      light
      class="pb-5"
      color="#333333"
    >
      <div class="d-flex justify-space-between py-3 px-5">
        <h3 class="py-2">
          Товар находится в санкционном списке
        </h3>
        <v-btn
          color="error"
          small
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div>
        <v-col class="white">
          <div class="rounded red white--text pa-4 ware-description">
            <p><b>№ товара:</b> {{ ware.sortIndex }}</p>
            <p><b>ТНВЭД:</b> {{ ware.code }}</p>
            <p><b>Описание:</b> {{ ware.title }}</p>
          </div>
        </v-col>
        <v-col class="grey px-4 lighten-3 sanctioned-product-description">
          <p><b>Категория:</b> {{ sanctionedProduct.name }}</p>
          <p><b>Торговая марка:</b> {{ sanctionedProduct.tradeMark }}</p>
          <p><b>Теги:</b> {{ sanctionedProduct.ownName }}</p>
          <p><b>ТНВЭД:</b> {{ sanctionedProduct.tnVed }}</p>
        </v-col>
      </div>
    </v-card>
  </v-overlay>
</template>
<script>

import {sanctionedProductNotification} from "@/events/common";

export default {
  inject: ['rootNamespace'],
  data: () => ({
    show:false,
    ware:{
      sortIndex:null,
      code:null,
      title:null
    },
    sanctionedProduct: {
      name:null,
      tradeMark:null,
      ownName:null,
      tnVed:null
    }
  }),
  created() {
    sanctionedProductNotification.subscribe(this.openModal)
  },
  beforeDestroy() {
    sanctionedProductNotification.unsubscribe()
  },
  methods:{
    clearState(){
      Object.keys(this.ware).forEach(key => this.ware[key] = null)
      Object.keys(this.sanctionedProduct).forEach(key => this.sanctionedProduct[key] = null)
    },
    close(){
      this.show = false
      this.clearState()
    },
    setWare(ware){
      Object.keys(this.ware).forEach(key => {
        if(key in ware){
          this.ware[key] = ware[key]
        }
      })
    },
    setSanctionedProduct(sanctionedProduct){
      Object.keys(this.sanctionedProduct).forEach(key => {
        if(key in sanctionedProduct){
          this.sanctionedProduct[key] = sanctionedProduct[key]
        }
      })
    },
    openModal(wareInformation){
      this.setWare(wareInformation.ware)
      this.setSanctionedProduct(wareInformation.sanctionedProduct)
      this.show = true
    }
  }
}
</script>
<style scoped>
.ware-description p,
.sanctioned-product-description p{
  margin-bottom: 5px;
}
</style>