import {findSanctionedProductByTag, getSanctionedProductsByCode, isSanctionedProductCode} from "@/helpers/catalogs";
import {sanctionedProductNotification} from "@/events/common";

export function notifyOnSanctionedProduct({ wareItems = [], uniqueSanctionedCodes = [], sanctionedProducts = [] }){
  const [currentWareItem] = wareItems
  const {tn_ved, title} = currentWareItem
  const sanctionedCode = isSanctionedProductCode(tn_ved, uniqueSanctionedCodes)
  if(sanctionedCode){
    const filteredSanctionedProducts = getSanctionedProductsByCode(sanctionedCode, sanctionedProducts)
    const sanctionedProduct = findSanctionedProductByTag(title, filteredSanctionedProducts)
    if(sanctionedProduct?.id){
      sanctionedProductNotification.trigger({
        ware:{
          sortIndex: currentWareItem.sort_index,
          code: currentWareItem.tn_ved,
          title: currentWareItem.title,
        },
        sanctionedProduct
      })
    }
  }
}