<template>
  <ware-card
    :id="block_id"
    :index="index"
    :sort-index="item.sort_index"
    selector-class="epi-goods-item"
    @outside="triggerOnFocus"
    @toggle="onToggle"
    @toggle-tick="onToggleTick"
  >
    <template #title>
      <ware-title :item="item" />
    </template>
    <template #menu>
      <v-list>
        <v-list-item
          class="wares-item-menu-delete"
          @click="deleteItem"
        >
          Удалить
        </v-list-item>
        <v-list-item
          class="wares-item-menu-copy"
          @click="copyItem"
        >
          Копировать
        </v-list-item>
      </v-list>
    </template>
    <template #body>
      <div
        class="statistics-box pa-4"
        :class="{'blocked-box' : disabledView}"
      >
        <code-block
          :index="index"
          :visited.sync="fields_been_visited"
          :has-changes.sync="hasChanges"
          :info="info"
          :control="waresControlPath"
          @update:info="updateInfoInComponent"
        />
        <description
          :index="index"
          :info="info"
          :visited.sync="fields_been_visited"
          :has-changes.sync="hasChanges"
          :ware-details="wareDetails"
          :show-quantity="showWareDetailQuantity"
          :control="waresControlPath"
          @update:info="updateInfoInComponent"
        />
        <v-row>
          <measure-units
            :index="index"
            :info="info"
            :visited.sync="fields_been_visited"
            :has-changes.sync="hasChanges"
            :control="waresControlPath"
            @update:info="updateInfoInComponent"
          />
          <v-divider vertical />
          <v-col cols="6">
            <parameters
              :index="index"
              :visited.sync="fields_been_visited"
              :has-changes.sync="hasChanges"
              :info="info"
              :control="waresControlPath"
              @update:info="updateInfoInComponent"
            />
            <preceding-document-list
              :index="index"
              :visited.sync="fields_been_visited"
              :has-changes.sync="hasChanges"
              :control="waresControlPath"
              :info="info"
              @update:info="updateInfoInComponent"
            />
          </v-col>
          <v-divider vertical />
          <payments
            :info="info"
            :index="index"
            :visited.sync="fields_been_visited"
            :has-changes.sync="hasChanges"
            :available-excises.sync="availableExcises"
            :control="waresControlPath"
            @update:info="updateInfoInComponent"
          />
        </v-row>
      </div>
    </template>
  </ware-card>
</template>

<script>
import { mapGetters } from "vuex";
import CodeBlock from "@/components/epi/form/goods/code.vue";
import Description from "@/components/epi/form/goods/description.vue";
import Parameters from "@/components/epi/form/goods/parameters.vue";
import MeasureUnits from "@/components/epi/form/goods/measure-units.vue";
import Payments from "@/components/epi/form/goods/payments.vue";
import PrecedingDocumentList from "@/components/epi/form/goods/preceding-documents/document-list.vue";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import formatDate from "@/mixins/format-date.js";
import { wares as onWares } from "@/events/statistics/control";
import blockVisibility from '@/mixins/block-visibility'

import {
  forceUpdateWareDetail,
  wareDetailModified,
  addPromise,
} from "@/events/statistics";
import cloneDeep from "lodash.clonedeep";
import { convertEmptyStringsToNull} from "@/helpers/objects";
import {getFirstValueFromMap, removeFirstFromMap} from "@/helpers/control";
import {notifyOnSanctionedProduct} from "@/helpers/wares-sanctioned-products";
import WareCard from "@/components/documents/ware/ware-card.vue";
import WareTitle from "@/components/documents/ware/ware-title.vue";
import {removeAddMeasureQuantity} from "@/helpers/ware-details";
import {patchStateInjector, readyToUpdateInjector} from "@/helpers/injectors";

export default {
  components: {
    WareTitle,
    WareCard,
    CodeBlock,
    Description,
    MeasureUnits,
    Parameters,
    Payments,
    PrecedingDocumentList,
  },
  mixins: [blockAutoUpdate, formatDate, blockVisibility],
  provide(){
    return{
      [readyToUpdateInjector]:this.readyToUpdate,
      [patchStateInjector]:this.updateInfoInComponent
    }
  },
  props: {
    item: {
      required: true,
      type: Object,
    },
    index: {
      required: true,
      type: Number,
    },
  },
  data: () => ({
    show: false,
    block_id: null,
    is_goods_item: true,
    info: {},
    title: "",
    wareDetails: [],
    promises: [],
    promisesWares: [],
    onWares: null,
    waresControlPath: {
      path:null
    },
    addMeasureLetterInput: null,
    availableExcises: {},
  }),
  computed: {
    ...mapGetters({
      selected: "epi/getSelected",
      shipmentIndex:"epi/getShipmentIndex",
      disabledView:"epi/getVisibility",
      uniqueSanctionedCodes:"catalogs/getUniqueSanctionedCodes",
      sanctionedProducts:"catalogs/getSanctionedProducts",
      user:"auth/getUser"
    }),
    showWareDetailQuantity() {
      return !!(this.info.add_measure_unit_letter
          || document.activeElement === this.addMeasureLetterInput) || false
    },
  },
  watch: {
    item: {
      handler(nv) {
        this.info = cloneDeep(nv);
        this.setBlockId()
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    wareDetailModified.subscribe(this.checkAndSetVisited);
    addPromise.subscribe(this.onComponentPromise);
    this.onWares = { ...onWares }; // Делаем локальную копию чтобы можно было менять имя события
    this.onWares.name = `${this.onWares.name}-${this.index + 1}`;
    this.onWares.subscribe(this.onControl);
  },
  beforeDestroy() {
    this.onWares.unsubscribe();
    wareDetailModified.unsubscribe();
    addPromise.unsubscribe();
  },
  methods: {
    setBlockId(){
      this.block_id = `epi-goods-item-${this.index}`;
    },
    markAsEditable(){
      if(!this.info.id) return
      this.$store.commit("epi/SET_EDITABLE_WARE", {
        id: this.info.id,
        index: this.index,
      });
    },
    onToggle(value){
      value ? this.markAsEditable() : this.destroyEventListeners()
    },
    onToggleTick(value){
      if(value){
        this.fetchWareDetails()
        this.setEventListeners()
        this.getAvailableExcises();
      }
    },
    onControl({path}) {
      const index = getFirstValueFromMap(path)
      const selector = ".epi-goods-item:nth-of-type(" + index + ")";
      const el = document.querySelector(selector);
      this.$scrollTo(el, 200, {
        force: true,
        onDone: () => {
          removeFirstFromMap(path)
          this.highlight({el, path})
        },
      });
    },
    highlight({ el, path }) {
      const [wrapper] = el.children;
      if (wrapper.classList.contains("transportation-box__header_close")) {
        const [toggle] = wrapper.children;
        const clickEvent = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: false,
        });
        toggle.dispatchEvent(clickEvent);
        this.waresControlPath = {path}
      } else {
        this.waresControlPath = {path}
      }
    },
    onComponentPromise({ id, promise, type = "block" }) {
      if (id === this.info.id) {
        type === "ware"
          ? this.promisesWares.push(promise)
          : this.promises.push(promise);
      }
    },
    onAddMeasureChanged({value}) {
      if ((!value || value === "") && this.wareDetails.length) {
        const updated = removeAddMeasureQuantity(this.wareDetails)
        if (!updated.length) return;
        this.$store.dispatch('epi/updateWareDetail', updated).then(() => {
          this.fetchWareDetails()
        })
      }
    },
    checkAndSetVisited({ index, action }) {
      if (this.index === index) {
        this.updateWareDetails(action);
        this.fieldsBeenVisitedByUser();
        this.triggerOnChange();
        if (["add", "delete"].includes(action)) {
          this.updateEventListeners();
        }
      }
    },
    updateWareDetails(action) {
      if (["add", "delete"].includes(action)) {
        this.fetchWareDetails();
      }
    },
    fetchWareDetails() {
      this.$store
        .dispatch("epi/fetchWareDetails", { wareId: this.info.id })
        .then((res) => {
          this.wareDetails = [...res.data];
        });
    },
    updateInfoInComponent(data) {
      this.info = {
        ...this.info,
        ...data,
      };
    },
    saveVehicleModel(wares){
      const {ware_transport_json} = wares[0]
      const vehicle = {
        id: null,
        division_id: this.user.user.activeDivisionId,
        mark_name: ware_transport_json.mark_name,
        model_name: ware_transport_json.model_name,
        user_id: this.user.user.id,
        date_update: null
      }
      this.$store.dispatch('catalogs/saveModel', {
        userId: this.user.user.id,
        vehicle
      })
    },
    uploadData() {
      return Promise.all(this.promises)
        .then(() => {
          this.promises = [];
          forceUpdateWareDetail.trigger({ id: this.info.id });
          return Promise.all(this.promisesWares);
        })
        .then(() => {
          this.promisesWares = [];
          const value = convertEmptyStringsToNull(this.info);
          return this.$store.dispatch("epi/updateShipmentGoodsItem", value);
        })
        .then((updateResponse) => {
          this.saveVehicleModel(updateResponse.data)
          notifyOnSanctionedProduct({
            wareItems: updateResponse.data,
            uniqueSanctionedCodes: this.uniqueSanctionedCodes,
            sanctionedProducts: this.sanctionedProducts
          })
          return updateResponse
        });
    },
    deleteItem() {
      const { id, sort_index } = this.info;
      const index = this.index;
      this.$store
        .dispatch("epi/deleteShipmentGoodsItem", {
          id,
          sort_index,
          index,
        })
        .then(() => this.$info("Товар удален"))
        .catch(() => this.$error("Ошибка удаления товара"))
    },
    copyItem() {
      const { id } = this.info;
      this.$store
        .dispatch("epi/copyShipmentWare", { id })
        .then(() => this.$info("Товар скопирован"))
        .catch((err) => this.$error(`Не удалось скопировать товар. ${err.response.data}`));
    },
    getAvailableExcises() {
      const tn_ved = this.info.tn_ved;
      if (tn_ved) {
        const payload = {
          tn_ved,
          date: this.formatDate(
            this.selected.about_declaration.declaration_date
          ),
        };
        this.$store
          .dispatch("epi/getAvailableExcise", payload)
          .then((value) => {
            this.availableExcises = { ...value };
          })
          .catch((e) => {
            if (e.color) {
              return this.$snackbar({
                text: e.message,
                color: e.color ?? "red",
                top: false,
              });
            }
            return this.$error("Ошибка загрузки акцизов")
          });
      }
    },
    readyToUpdate() {
      this.hasChanges = true;
      this.fields_been_visited = true;
    },
  },
};
</script>
