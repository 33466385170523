<template>
  <index />
</template>
<script>
import Index from '@/components/epi/form/index.vue'
export default {
  components:{
    Index,
  },
  provide:{
    rootNamespace:'epi'
  }
}
</script>
