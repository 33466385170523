<template>
  <li
    class="py-1"
  >
    <v-row>
      <v-col
        cols="1"
        class="font-weight-bold"
      >
        {{ index + 1 }}.
      </v-col>
      <v-col
        cols="2"
        class="px-0"
      >
        {{ document.doc_code }}
      </v-col>
      <v-col
        cols="4"
        class="px-0"
      >
        {{ document.doc_number }}
      </v-col>
      <v-col
        cols="3"
        class="date px-0"
      >
        {{ document.doc_date }}
      </v-col>
      <v-col
        cols="2"
        class="px-0"
      >
        {{ document.ware_range }}
      </v-col>
    </v-row>
  </li>
</template>
<script>
import moment from "moment";
export default {
  props: {
    item: {
      required: true,
      type: Object,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    document: {
      doc_code: "",
      doc_number: "",
      doc_date: "",
      ware_range: "",
    },
  }),
  watch: {
    item: {
      handler() {
        this.setDocument();
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    setDocument() {
      const document = this.item;
      const number = document.doc_number ? `${document.doc_number}` : "";
      const date = document.doc_date
        ? `от ${moment(document.doc_date).format("DD.MM.YYYY")}`
        : "";
      const range =
        document.ware_range && document.ware_range.length
          ? document.ware_range.length > 5
            ? `(${document.ware_range.slice(0, 5)}...)`
            : `(${document.ware_range})`
          : "";

      this.document = {
        doc_code: document.doc_code,
        doc_number: number,
        doc_date: date,
        ware_range: range,
      };
    },
  },
};
</script>
<style scoped>
  .date{
    max-width: 140px;
  }
</style>