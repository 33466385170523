<template>
  <v-col
    :id="block_id"
    v-click-outside="triggerOnFocus"
    cols="12"
  >
    <div
      class="statistics-box pa-4"
      :class="{'blocked-box' : disabledView}"
    >
      <v-row>
        <v-col cols="9">
          <div class="font-weight-bold d-flex align-center mt-2">
            Водитель
            <v-menu offset-x>
              <template #activator="{ on, attrs }">
                <v-icon
                  class="mx-1 mr-4"
                  tabindex="-1"
                  small
                  dense
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-tune
                </v-icon>
              </template>
              <v-list>
                <v-list-item> Поиск в справочнике </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-col>
        <v-col
          cols="3"
          class="d-flex justify-end align-center"
        >
          <v-autocomplete
            :value="search"
            class="ml-16"
            :loading="loading"
            :readonly="loading"
            :items="drivers"
            item-text="name"
            :filter="filterBySearchField"
            placeholder="Поиск по паспорту"
            auto-select-first
            return-object
            append-icon="mdi-magnify"
            tabindex="-1"
            background-color="white"
            dense
            outlined
            hide-details="true"
            @change="onSearchChange"
            @update:search-input="fetchDrivers"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="2"
          class="pb-0"
        >
          <label>
            Страна
            <v-autocomplete
              ref="country_letter"
              v-model="driver_json.country_letter"
              auto-select-first
              outlined
              dense
              hide-details="auto"
              background-color="white"
              :filter="filterBySearchField"
              :items="countries"
              item-text="text"
              item-value="letterCode"
            />
          </label>
        </v-col>
        <v-col
          cols="4"
          class="pb-0"
        >
          <label>
            Код вида документа
            <v-autocomplete
              ref="identity_doc_name"
              v-model="driver_json.identity_doc_name"
              :items="identityDocumentsFiltered"
              :filter="filterBySearchField"
              item-text="search"
              item-value="name"
              auto-select-first
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>
        <v-col
          cols="2"
          class="pb-0 pr-1"
        >
          <label>
            Номер
            <v-text-field
              ref="driver_identity_doc_number"
              v-model="driver.driver_identity_doc_number"
              class="mr-1"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>
        <v-col
          cols="2"
          class="pb-0"
        >
          <label>
            Дата выдачи
            <custom-date-picker
              ref="identity_doc_date"
              v-model="driver_json.identity_doc_date"
              :visited.sync="fields_been_visited"
              :has-changes.sync="hasChanges"
            />
          </label>
        </v-col>
        <v-col cols="2" />
        <v-col
          cols="2"
          class="pb-0"
        >
          <label>
            Фамилия
            <v-text-field
              ref="driver_last_name"
              v-model="driver.driver_last_name"
              class="mr-1"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>
        <v-col
          cols="2"
          class="pb-0"
        >
          <label>
            Имя
            <v-text-field
              ref="driver_first_name"
              v-model="driver.driver_first_name"
              class="mr-1"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>
        <v-col
          cols="2"
          class="pb-0"
        >
          <label>
            Отчество
            <v-text-field
              ref="driver_middle_name"
              v-model="driver.driver_middle_name"
              class="mr-1"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>
        <v-col
          v-if="!contacts.length"
          class="d-flex align-end justify-end mb-1 pb-0"
          :offset="4"
        >
          <v-btn
            dense
            class="elevation-0"
            @click="addContactItem"
          >
            <v-icon>mdi-plus</v-icon> Контакт
          </v-btn>
        </v-col>
        <v-col
          v-if="contacts.length"
          ref="contacts"
          :offset="2"
          cols="4"
        >
          <single-contact
            v-for="(contact, index) in contacts"
            :ref="'cont_' + index"
            :key="index"
            :types="contact_types"
            :item="contact"
            :idx="index"
            :contacts-length="contacts.length"
            @deleteContactItem="deleteContactItem"
            @addContactItem="addContactItem"
            @contactsUpdater="contactsUpdater"
          />
        </v-col>
        <v-col
          cols="4"
          align-self="end"
        >
          <v-btn
            dense
            class="mb-1  mr-2 elevation-0"
            @click="copyToFiller"
          >
            <v-icon>mdi-content-copy</v-icon> в Предоставивший ЭПИ
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-col>
</template>

<script>
import CustomDatePicker from "@/components/shared/custom-date-picker.vue";
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import { mapGetters } from "vuex";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import {
  getCountryNameByCode,
  getIdentityCodeByName, getIdentityNameByCode,
} from "@/helpers/catalogs";
import { convertEmptyStringsToNull } from "@/helpers/objects";
import cloneDeep from "lodash.clonedeep";
import { driver as onDriver } from "@/events/statistics/control";
import { highlightField } from "@/helpers/control";
import SingleContact from "@/components/shared/single-contact.vue";
import contactsMixin from "@/mixins/contacts.mixin";
import {copyToBlock} from "@/components/epi/form/copy-to-block";

export default {
  components: {SingleContact, CustomDatePicker },
  mixins: [filterBySearchFieldMixin, blockAutoUpdate, contactsMixin],
  data: () => ({
    block_id: "epi-driver",
    loading:false,
    search: null,
    drivers:[],
    driver: {
      declaration_id: null,
      driver_first_name: "",
      driver_identity_doc_number: "",
      driver_last_name: "",
      driver_middle_name: "",
    },
    driver_json: {
      country_letter: "",
      country_name: "",
      identity_doc_code: "",
      identity_doc_name: "",
      identity_doc_date: "",
      position: "",
    },
    contacts: [],
  }),
  computed: {
    ...mapGetters({
      selected: "epi/getSelected",
      identityDocuments: "catalogs/getNsiIdentityDocuments",
      contact_types: "catalogs/getContactTypes",
      user:'auth/getUser',
      countries:'catalogs/getNsiCountries',
      disabledView:"epi/getVisibility"
    }),
    divisionId() {
      return this.user?.user?.activeDivisionId;
    },
    identityDocumentsFiltered() {
      if (this.driver_json.country_letter) {
        if (
          ["BY", "RU", "KZ", "KG", "AM"].includes(
            this.driver_json.country_letter
          )
        ) {
          return this.identityDocuments.filter((item) => {
            return item.code.startsWith(this.driver_json.country_letter);
          });
        }
        return this.identityDocuments.filter((item) => {
          return item.code.startsWith("XX");
        });
      }
      return this.identityDocuments;
    },
  },
  watch: {
    // search(nv) {
    //   if (nv && Object.keys(nv).length) {
    //     this.updateFieldsOnSearch(nv);
    //     this.search = null;
    //     this.carriers = [];
    //     this.forceUpload();
    //   }
    // },
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
    },
  },
  created() {
    onDriver.subscribe(this.highlightField);
  },
  beforeDestroy() {
    onDriver.unsubscribe();
  },
  methods: {
    highlightField,
    onSearchChange(nv){
      this.updateFieldsOnSearch(nv);
      this.search = null;
      this.drivers = [];
      this.forceUpload();
    },
    updateFieldsOnSearch(item) {
      this.driver = {
        ...this.driver,
        driver_first_name: item.first_name,
        driver_identity_doc_number: item.identity_doc_number,
        driver_last_name: item.last_name,
        driver_middle_name: item.middle_name,
      }
      this.driver_json = {
        ...this.driver_json,
        country_letter: item.country_letter,
        country_name: item.country_name,
        identity_doc_code: item.identity_doc_code,
        identity_doc_name: getIdentityNameByCode(this.identityDocuments, item.identity_doc_code),
        identity_doc_date: item.identity_doc_date,
        position: "",
      }
      this.contacts = item.contacts.map(i => {
        return {
          code:i.code,
          name:i.name,
          number:i.contact,
        }
      })
    },
    saveDriver(){
      const country_name = getCountryNameByCode(this.countries, this.driver_json.country_letter)
      const division_id = this.user.user.activeDivisionId
      const contactsRaw = this.contacts.map(i => {
        return {
          code:i.code,
          name:i.name,
          contact: i.number
        }
      })
      const contacts = this.getContactsWithName(contactsRaw)
      const payload = {
        id: null,
        country_letter: this.driver_json.country_letter,
        identity_doc_code: this.driver_json.identity_doc_code,
        identity_doc_number: this.driver.driver_identity_doc_number,
        identity_doc_date: this.driver_json.identity_doc_date,
        first_name: this.driver.driver_first_name,
        last_name: this.driver.driver_last_name,
        middle_name: this.driver.driver_middle_name,
        user_id: this.user.user.id,
        country_name,
        division_id,
        contacts
      }
      this.$store.dispatch("catalogs/saveDriver", payload)
    },
    forceUpload() {
      return this.uploadData()
        .then((res) => {
          this.resetTriggers()
          this.$snackbar({
            text: "Обновлено [Водитель]",
            color: "green",
            top: false,
            right: false,
          });
          return res;
        })
        .catch((err) => {
          this.$snackbar({
            text: "Ошибка",
            color: "red",
            top: false,
            right: false,
          });
          return Promise.reject(err);
        });
    },
    fetchDrivers(value){
      if (value && value.length > 3) {
        this.loading = true;
        this.$store
          .dispatch("catalogs/searchDriver", {
            value,
            divisionId: this.divisionId,
          })
          .then((res) => {
            this.loading = false;
            const items = res.data.map((i) => {
              const {middle_name, last_name, identity_doc_number, identity_doc_code, first_name} = i
              return {
                ...i,
                search: [last_name,first_name,middle_name, identity_doc_number, identity_doc_code].join('-'),
                name:[last_name,first_name,middle_name].join(' ')
              };
            });
            this.drivers = [...items];
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    setFields() {
      this.contacts = cloneDeep(this.selected.driver.driver_json.contacts) || [];

      const driver = this.selected.driver;
      const driver_json = driver.driver_json;

      Object.keys(this.driver).forEach((key) => {
        if (key in driver) {
          this.driver[key] = driver[key];
        }
      });

      Object.keys(this.driver_json).forEach((key) => {
        if (key in driver_json) {
          this.driver_json[key] = driver_json[key];
        }
      });
    },
    copyToFiller(){
      this.uploadData().then(() => {
        copyToBlock.call(this, 'driver', 'filler')
      }).catch(() => {
        this.$snackbar({
          text: "Ошибка сохранения блока Перевозчик",
          color: "red",
          top: false,
        });
      });
    },
    updateSubmitted() {
      const filler_json = {
        last_name: this.driver.driver_last_name,
        first_name: this.driver.driver_first_name,
        middle_name: this.driver.driver_middle_name,
        position: "ВОДИТЕЛЬ",
        country_letter: this.driver_json.country_letter,
        country_name: getCountryNameByCode(
          this.countries,
          this.driver_json.country_letter
        ),
        identity_doc_name: this.driver_json.identity_doc_name,
        identity_doc_code:
          getIdentityCodeByName(
            this.identityDocuments,
            this.driver_json.identity_doc_name
          ) || null,
        identity_doc_number: this.driver.driver_identity_doc_number,
        identity_doc_d_on: this.driver_json.identity_doc_date,
        identity_doc_d_off: "",
        auth_doc_number: "",
        auth_doc_d_on: "",
        auth_doc_d_off: "",
        certificate_number: "",
        customs_agent_contract_num: "",
        customs_agent_contract_date: "",
        customs_agent_license_num: "",
        contacts: this.getContactsWithName()
      };
      const filler = {
        declaration_id: this.driver.declaration_id,
        filler_json,
      };
      const value = convertEmptyStringsToNull(filler);
      return this.$store.dispatch("epi/uploadBlockData", {
        name: "filler",
        value,
      });
    },
    uploadData() {
      const country_name = getCountryNameByCode(
        this.countries,
        this.driver_json.country_letter
      );
      const payload = {
        ...this.driver,
        driver_json: {
          ...this.driver_json,
          country_name,
          identity_doc_code:
            getIdentityCodeByName(
              this.identityDocuments,
              this.driver_json.identity_doc_name
            ) || null,
          contacts: this.getContactsWithName()
        },
      };
      const driver = this.convertEmptyStringsToNull(payload);
      return this.$store.dispatch("epi/uploadBlockData", {
        name: "driver",
        value: driver,
      }).then((res) => {
        this.saveDriver()
        return res
      });
    },
  },
};
</script>
