<template>
  <v-col
    cols="3"
    class="pb-0"
  >
    <label>№ контейнера 
      <v-menu
        v-if="index === 0"
        offset-x
      >
        <template #activator="{ on, attrs }">
          <v-icon
            class="mx-1"
            tabindex="-1"
            small
            dense
            v-bind="attrs"
            v-on="on"
          >mdi-tune</v-icon>
        </template>
        <v-list>
          <v-list-item @click="$emit('copy', 'all')">
            Применить блок ко всем товарам
          </v-list-item>
          <v-list-item @click="$emit('copy', 'selected')">
            Применить блок к выбранным товарам
          </v-list-item>
        </v-list>
      </v-menu>
    </label>  
    <div>
      <v-text-field
        v-model="number"
        outlined
        dense
        hide-details="auto"
        background-color="white"
        @change="changed"
      >
        <template #append-outer>
          <v-icon
            small
            @click="remove"
          >
            <!-- @click="deleteContainer(idx)" -->
            mdi-delete
          </v-icon>
          <v-icon
            small
            @click="add"
          >
            <!-- @click="addContainer" -->
            mdi-plus
          </v-icon>
        </template>
      </v-text-field>
    </div>
  </v-col>
</template>
<script>
export default {
  props:{
    index:{
      required:true,
      type:Number
    },
    item:{
      required:true,
      type:Object
    }
  },
  data:() => ({
    number:""
  }),
  created(){
    this.number = this.item.number
  },
  methods:{
    add(){
      this.$emit('add')
    },
    remove(){
      this.$emit('delete', this.index)
    },
    changed(number){
      const value = {number}
      this.$emit('change', {index:this.index, value})
    }
  }
}
</script>