<template>
  <v-container
    fluid
    class="grey lighten-3"
  >
    <v-row>
      <v-col cols="2">
        <label>
          Наименование автомобиля
        </label>
        <v-autocomplete
          v-model="state.vehicle_type_code"
          auto-select-first
          background-color="white"
          dense
          outlined
          hide-details="true"
          item-value="code"
          item-text="name"
          :items="vehicleTypes"
          :filter="filterBySearchField"
        />
      </v-col>
      <v-col cols="2">
        <label>
          Марка автомобиля
        </label>
        <v-autocomplete
          :value="state.mark_name"
          item-text="name"
          item-value="name"
          :items="transportMarks"
          auto-select-first
          background-color="white"
          dense
          outlined
          hide-details="true"
          :filter="filterBySearchField"
          @change="onMarkChange"
        />
      </v-col>
      <v-col cols="2">
        <label>
          Модель автомобиля
        </label>
        <v-combobox
          :value="state.model_name"
          :items="filteredModels"
          auto-select-first
          class="mr-1"
          outlined
          dense
          hide-details="auto"
          background-color="#FFFFFF"
          @update:search-input="selectModel"
        />
      </v-col>
      <v-col cols="2">
        <label>
          Год выпуска
        </label>
        <custom-date-picker v-model="state.release_date" />
      </v-col>
      <v-col cols="2">
        <label>
          VIN
        </label>
        <v-text-field
          v-model="state.vin"
          v-mask="mask"
          class="mr-1"
          outlined
          dense
          hide-details="auto"
          background-color="#FFFFFF"
        />
      </v-col>
      <v-col
        cols="2"
      >
        <v-row>
          <v-col cols="8">
            <label>Объем (см3)</label>
            <input-numeric
              v-model="state.volume"
              :fraction="0"
              outlined
              dense
              hide-details="auto"
            />
          </v-col>
          <v-col
            cols="4"
            align-self="end"
          >
            <v-btn
              elevation="0"
              height="40px"
              min-width="40px"
              width="40px"
              @click="clearState"
            >
              <v-icon>mdi-broom</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import CustomDatePicker from "@/components/shared/custom-date-picker.vue";
import {filterBySearchFieldMixin} from "@/mixins/catalogs";
import {getTransportCodeByName, getVehicleTypeNameByCode} from "@/helpers/catalogs";
import {mapGetters} from "vuex";
import InputNumeric from "@/components/ui/input-numeric.vue";


export default {
  components: {InputNumeric, CustomDatePicker},
  mixins:[filterBySearchFieldMixin],
  props:{
    vehicle:{
      required:true,
      type:Object
    },
    transportMarks:{
      required:true,
      type:Array
    },
    vehicleTypes:{
      required:true,
      type:Array
    }
  },
  data(){
    return{
      state:{
        vehicle_type_code: null,
        vehicle_type_name: null,
        mark_code: null,
        mark_name: null,
        model_name: null,
        release_date: null,
        vin: null,
        volume:null,
      },
      models:[],
      mask:{
        mask: 'CCCCCCCCCCCCCCCCC',
        tokens: {
          'C': {
            pattern: /(?![QIOqio])[0-9a-zA-Z]/,
            transform: function(v) {
              return v.toLocaleUpperCase();
            }
          }
        }
      },
    }
  },
  computed:{
    ...mapGetters({
      user:'auth/getUser'
    }),
    filteredModels(){
      const modelName = this.state.model_name?.toLowerCase()
      return modelName ? this.models.filter(model => model.toLowerCase().startsWith(modelName)) : this.models
    }
  },
  watch:{
    vehicle:{
      handler(){
        this.setFields()
        this.fetchModels()
      },
      immediate:true,
      deep:true
    },
    state:{
      handler(vehicle){
        const completeVehicle = this.getCompleteVehicleState(vehicle)
        this.$emit('update', completeVehicle)
      },
      deep:true
    },
  },
  methods:{
    onMarkChange(markName){
      this.state.mark_name = markName
      this.fetchModels()
    },
    selectModel(value){
      this.state.model_name = value
    },
    fetchModels(){
      this.$store.dispatch('catalogs/searchModels', {
        markName: this.state.mark_name,
        divisionId:this.user.user.activeDivisionId
      }).then(res => {
        this.models = res.data.map(model => model.model_name)
      })
    },
    clearState(){
      Object.keys(this.state).forEach(key => {
        this.state[key] = null
      })
      this.$emit('clear', this.state)
    },
    getCompleteVehicleState(vehicle){
      const vehicle_type_name = getVehicleTypeNameByCode(this.vehicleTypes, this.state.vehicle_type_code)
      const mark_code = getTransportCodeByName(this.transportMarks, this.state.mark_code)
      return {
        ...vehicle,
        mark_code,
        vehicle_type_name
      }
    },
    setFields(){
      Object.keys(this.state).forEach(key => {
        if(key in this.vehicle){
          this.state[key] = this.vehicle[key]
        }
      })
    }
  }
}
</script>
