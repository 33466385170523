<template>
  <v-col cols="12">
    <div
      class="statistics-box pa-4"
      :class="{'blocked-box' : disabledView}"
    >
      <v-row
        :id="block_id"
        v-click-outside="triggerOnFocus"
      >
        <v-col
          cols="12"
        >
          <v-row>
            <v-col
              cols="8"
              class="pb-0"
            >
              <div class="font-weight-bold d-flex align-center mt-2">
                Декларант таможенной процедуры таможенного транзита
                <v-menu offset-x>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      class="mx-1 mr-4"
                      tabindex="-1"
                      small
                      dense
                      v-bind="attrs"
                      v-on="on"
                      @click="onMenuOpen"
                    >
                      mdi-tune
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item disabled>
                      Поиск в справочнике
                    </v-list-item>
                    <v-list-item
                      disabled
                      @click="show = true"
                    >
                      Сохранить перевозчика в справочник
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-col>
            <v-col
              cols="4"
              class="d-flex justify-end align-center"
            >
              <v-autocomplete
                v-model="search"
                class="ml-16"
                :loading="loading"
                :readonly="loading"
                :items="carriers"
                item-text="own_name"
                :filter="filterBySearchField"
                placeholder="Поиск в справочнике"
                auto-select-first
                return-object
                append-icon="mdi-magnify"
                background-color="white"
                dense
                outlined
                hide-details="true"
                tabindex="-1"
                @update:search-input="fetchCarriers"
              />
              <div class="d-flex justify-end">
                <v-btn
                  min-width="32px"
                  class="ml-2"
                  text
                  @click="collapsed = !collapsed"
                >
                  <v-icon>
                    {{ collapsed ? "mdi-chevron-down" : "mdi-chevron-up" }}
                  </v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row v-show="!collapsed">
            <v-col
              cols="1"
              class="pb-0"
            >
              <label>
                Страна
                <v-autocomplete
                  ref="country_letter"
                  v-model="principal_json.country_letter"
                  auto-select-first
                  outlined
                  :filter="filterBySearchField"
                  dense
                  hide-details="auto"
                  background-color="white"
                  :items="countries"
                  item-text="letterCode"
                  item-value="letterCode"
                />
              </label>
            </v-col>
            <v-col
              cols="2"
              class="pb-0"
            >
              <label>
                Уникальный номер
                <v-text-field
                  ref="principal_inn"
                  v-model="principal.principal_inn"
                  class="mr-1"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="3"
              class="pb-0"
            >
              <label>
                Наименование
                <v-text-field
                  ref="principal_name"
                  v-model="principal.principal_name"
                  class="mr-1"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col cols="6" />
            <v-col
              cols="1"
            >
              <label>
                Индекс
                <v-text-field
                  ref="postal_index"
                  v-model="principal_json.postal_index"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="3"
            >
              <label>
                Область/Район
                <v-text-field
                  ref="region"
                  v-model="principal_json.region"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="2"
            >
              <label>
                Город/Деревня
                <v-text-field
                  ref="city"
                  v-model="principal_json.city"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="3"
            >
              <label>
                Улица
                <v-text-field
                  ref="street_house"
                  v-model="principal_json.street_house"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="1"
            >
              <label>
                Дом
                <v-text-field
                  ref="house"
                  v-model="principal_json.house"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="2"
            >
              <label>
                Номер помещения
                <v-text-field
                  ref="room"
                  v-model="principal_json.room"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
          </v-row>
          <principal-collapsed
            v-show="collapsed"
            :json="principal_json"
            :value="principal"
          />
        </v-col>
      </v-row>
    </div>
    <archive
      :show.sync="show"
      entity="principal"
      @save="saveToArchive"
    />
  </v-col>
</template>

<script>
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import { getCountryNameByCode } from "@/helpers/catalogs";
import { mapGetters } from "vuex";
import Archive from "@/components/epi/form/contragents/archive.vue";
import { highlightField } from "@/helpers/control";
import { principal as onPrincipal } from "@/events/statistics/control";
import {convertEmptyStringsToNull} from "@/helpers/objects";
import PrincipalCollapsed from "@/components/epi/form/principal-collapsed.vue";
export default {
  components: { Archive, PrincipalCollapsed },
  mixins: [filterBySearchFieldMixin, blockAutoUpdate],
  data: () => ({
    block_id: "epi-principal",
    collapsed:true,
    principal: {
      declaration_id: null,
      principal_inn: "",
      principal_name: "",
    },
    principal_json: {
      country_letter: "",
      country_name: "",
      postal_index: "",
      region: "",
      city: "",
      street_house: "",
      house: "",
      room: "",
    },
    // поиск в справочнике
    search: null,
    loading: false,
    carriers: [],
    show: false,
  }),
  computed: {
    ...mapGetters({
      user: "auth/getUser",
      selected: "epi/getSelected",
      countries: "catalogs/getNsiCountries",
      disabledView:"epi/getVisibility"
    }),
    divisionId() {
      return this.user?.user?.activeDivisionId;
    },
  },
  watch: {
    search(nv) {
      if (nv && Object.keys(nv).length) {
        this.updateFieldsOnSearch(nv);
        this.search = null;
        this.carriers = [];
        this.forceUpload();
      }
    },
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
    },
  },
  created() {
    onPrincipal.subscribe(this.onPrincipalControl);
  },
  beforeDestroy() {
    onPrincipal.unsubscribe();
  },
  methods: {
    onPrincipalControl(payload){
      this.collapsed = false
      this.$nextTick(() => {
        this.highlightField(payload)
      })
    },
    highlightField,
    saveToArchive(own_name) {
      const payload = {
        id: null,
        own_name: own_name,
        division_id: this.divisionId,
        root_parent_division_id: null,
        name: this.principal.principal_name,
        inn: this.principal.principal_inn,
        country_letter: this.principal_json.country_letter,
        country_name: this.principal_json.country_name,
        region: this.principal_json.region,
        city: this.principal_json.city,
        street: this.principal_json.street_house,
        house: this.principal_json.house,
        room: this.principal_json.room,
        postal_index: this.principal_json.postal_index,
        contacts_json: [],
        branch_name: null,
        branch_inn: null,
        branch_country_letter: null,
        branch_country_name: null,
        branch_region: null,
        branch_city: null,
        branch_street: null,
        branch_house: null,
        branch_room: null,
        branch_postal_index: null,
        branch_contacts_json: [],
      };

      this.$store
        .dispatch("catalogs/saveCarrier", payload)
        .then(() => {
          this.$snackbar({
            text: "Справочник обновлен",
            color: "blue",
            top: false,
            right: false,
          });
        })
        .catch((err) => {
          if (err.response.status === 400) {
            this.$snackbar({
              text: err.response.data || "Ошибка",
              color: "red",
              top: false,
              right: false,
            });
          } else {
            this.$snackbar({
              text: "Ошибка",
              color: "red",
              top: false,
              right: false,
            });
          }
        });
    },
    updateFieldsOnSearch(item) {
      this.principal.principal_inn = item.inn;
      this.principal.principal_name = item.name;

      Object.keys(this.principal_json).forEach((key) => {
        if (key in item) {
          this.principal_json[key] = item[key];
        }
      });
      this.principal_json.street_house = item.street;
    },
    setFields() {
      const principal = this.selected.principal;
      const principal_json = this.selected.principal.principal_json;

      Object.keys(this.principal).forEach((key) => {
        if (key in principal) {
          this.principal[key] = principal[key];
        }
      });

      Object.keys(this.principal_json).forEach((key) => {
        if (key in principal_json) {
          this.principal_json[key] = principal_json[key];
        }
      });

    },
    forceUpload() {
      return this.uploadData()
        .then((res) => {
          this.$snackbar({
            text: "Обновлено [Перевозчик]",
            color: "green",
            top: false,
            right: false,
          });
          return res;
        })
        .catch((err) => {
          this.$snackbar({
            text: "Ошибка",
            color: "red",
            top: false,
            right: false,
          });
          return Promise.reject(err);
        });
    },
    onMenuOpen() {
      if (this.hasChanges && this.fields_been_visited) {
        this.forceUpload().then(() => {
          this.fields_been_visited = false;
          this.hasChanges = false;
        });
      }
    },
    uploadData() {
      const principal = this.convertEmptyStringsToNull({
        ...this.principal,
        principal_json: {
          ...this.principal_json,
          country_name:
              getCountryNameByCode(
                this.countries,
                this.principal_json.country_letter
              ) || null,
        },
      });
      return this.$store.dispatch("epi/uploadBlockData", {
        name: "principal",
        value: principal,
      });
    },
    updateDeclarant(){
      const principal = this.selected.principal
      const principal_json = principal.principal_json
      const combined = {
        ...principal
      }
      const combined_json = {
        ...principal_json,
      }
      Object.keys(principal).filter(k => k !== 'principal_json').forEach(key => {
        const original = key.replace('principal_', 'principal_')
        if(original in this.principal){
          combined[key] = this.principal[original]
        }
      })
      Object.keys(principal_json).forEach(key => {
        if(key in this.principal_json){
          combined_json[key] = this.principal_json[key]
        }
      })
      const payload = convertEmptyStringsToNull({
        ...combined,
        principal_json: {
          ...combined_json,
        }
      })
      this.$store.dispatch("epi/uploadBlockData", {
        name: "principal",
        value: payload,
      }).then(() => {
        this.$snackbar({
          text: "Обновлено [Декларант]",
          color: "green",
          top: false,
        });
      })
        .catch(() => {
          this.$snackbar({
            text: "Ошибка сохранения блока Декларант",
            color: "red",
            top: false,
          });
        });
    },
    copyToDeclarant(){
      this.uploadData().then(() => {
        this.updateDeclarant()
      }) .catch(() => {
        this.$snackbar({
          text: "Ошибка сохранения блока Перевозчик",
          color: "red",
          top: false,
        });
      });
    },
    fetchCarriers(value) {
      if (value && value.length > 3) {
        this.loading = true;
        this.$store
          .dispatch("catalogs/searchCarrier", {
            value,
            divisionId: this.divisionId,
          })
          .then((res) => {
            this.loading = false;
            const items = res.data.map((i) => {
              return {
                ...i,
                search: `${i.name}-${i.own_name}-${i.unp || i.inn}-${i.city}-${
                  i.street
                }`,
              };
            });
            this.carriers = [...items];
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
