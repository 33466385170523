<template>
  <div
    class="d-flex align-center justify-space-between mb-2 prevent-trigger-update"
  >
    <div style="width:calc(100% - 72px);">
      <v-text-field
        v-model="container_item.number"
        outlined
        dense
        hide-details="auto"
        background-color="white"
        @change="updateFields"
      />
    </div>
    <v-btn
      class="document-add elevation-0"
      color="transparent"
      min-width="36px"
      max-width="36px"
      @click="deleteContainerItem"
    >
      <v-icon>mdi-delete</v-icon>
    </v-btn>
    <v-btn
      :disabled="!containerIndicator"
      class="document-add elevation-0"
      color="transparent"
      min-width="36px"
      max-width="36px"
      @click="addContainerItem"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </div>
</template>

<script>

export default {
  props: {
    containerIndicator: {
      required: true,
      validator: (v) => typeof v === 'boolean' || v === null
    },
    item: {
      required: true,
      type: Object
    },
    idx: {
      required: true,
      type: Number
    },
  },
  data: () => ({
    container_item: {
      number: null
    },
  }),
  watch:{
    item:{
      handler(newVal) {
        Object.keys(newVal).forEach(key => {
          if(key in this.container_item){
            this.container_item[key] = newVal[key]
          }
        })
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    deleteContainerItem() {
      this.$emit('delete', this.idx)
    },
    addContainerItem() {
      this.$emit('add')
    },
    updateFields() {
      this.$emit('update', {container: this.container_item, index: this.idx})
    },
  }
}
</script>